import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { ProjectService } from '@shared/services/project.service';
import { ToastService } from '@emendis/shared';
import { SettingService } from '@shared/services/setting.service';

@Injectable()
export class ContactListGuard implements CanActivate {

  public constructor(
    private projectService: ProjectService,
    private toastService: ToastService,
    private settings: SettingService) {
  }

  /**
   * Check if project-client has any contact persons
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Promise<boolean>}
   */
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!await this.projectService.hasContacts(route.parent.params.project) && this.settings.getSettingValue('client.required')) {
      this.toastService.error('toast.error.title', 'contact.no_results.message');

      return false;
    }

    return true;
  }
}
