import { Component, OnInit, HostListener } from '@angular/core';
import { detectBody } from '../../app.helpers';

declare var jQuery: any;

@Component({
  selector: 'app-basic',
  templateUrl: 'basicLayout.component.html'
})
export class BasicLayoutComponent implements OnInit {

  public ngOnInit(): any {
    detectBody();
  }

  @HostListener('window:resize') onResize() {
    detectBody();
  }

}
