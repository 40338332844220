import { Injectable } from '@angular/core';
import { ApiService, ApiHttpService } from '@emendis/api';
import { LocaleTenant as LocaleDomain } from '@domain/models/locale.model';
import Locale from '@emendis/auth/lib/locale.interface';
import { Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { DataService, QueryOptions } from './data.service';
import { Observable } from 'rxjs';
import { isUnavailable } from '@shared/services/util.service';

@Injectable({
  providedIn: 'root'
})
export class LocaleService extends ApiService<Locale> {
  protected endpoint = 'tenant/locale';

  public localesChanged$: Subject<void> = new Subject<void>();

  constructor(private api: ApiHttpService, private dataService: DataService) {
    super(api);
  }

  /**
   * Get the currently set language
   *
   * @returns string
   */
  public static getCurrentLocaleCode(): string {
    const localeId = localStorage.getItem('currentLocaleCode');

    if (localeId) {
      return localeId;
    }

    return JSON.parse(localStorage.getItem('user')).locale_id;
  }

  /**
   * Determine if we have locales in IndexedDB, if not; fetch from API
   *
   * @return Observable<any>
   */
  public indexLocales(): Observable<any> {
    this.dataService.get('locales', new QueryOptions({ usePaging: false }), '/tenant/locale').then((locales) => {
      if (isUnavailable(locales) === true) {
        return this.index();
      }

      return Observable.from(locales);
    });

    return;
  }

  /**
   * Set the current used locale id
   *
   * @param localeId: string
   * @returns void
   */
  public static setCurrentLocaleCode(localeId: string): void {
    localStorage.setItem('currentLocaleCode', localeId);
  }

  /**
   * Load the locales and store in IndexedDB
   *
   * @returns Promise<void>
   */
  public async loadLocales(): Promise<void> {
    const locales = await this.index()
      .pipe(
        map((response: any) => response.data),
        catchError(async () => {
          await LocaleDomain.loadLocales();
          this.localesChanged$.next();

          return false;
        })
      ).toPromise();

    if (locales.length) {
      await LocaleDomain.query.clear();

      await LocaleDomain.query.bulkAdd(locales);
      await LocaleDomain.loadLocales();
      this.localesChanged$.next();
    }
  }
}
