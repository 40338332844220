import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Libraries, 3d party components
import { PaginationModule } from 'ngx-bootstrap';
import { TableModule as EmendisTableModule } from '@emendis/table';
import { SharedModule as EmendisSharedModule } from '@emendis/shared';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import {
  ButtonModule,
  CarouselModule,
  ConfirmDialogModule,
  DataTableModule,
  DropdownModule,
  InputSwitchModule,
  MessageService,
  MultiSelectModule,
  SelectButtonModule,
  TooltipModule
} from 'primeng/primeng';
import { FormlyModule } from '@ngx-formly/core';
import { TranslateModule, TranslatePipe, TranslateService } from '@ngx-translate/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SignaturePadModule } from 'angular2-signaturepad';

// Custom components
import { FormModule } from '@emendis/formly';
import { OfflineTableComponent } from '@shared/controls/table/offline-table.component';
import { AsteriksComponent } from '@shared/controls/asteriks/asteriks.component';
import { AppPatternRestrictDirective } from '@shared/directives/app-pattern-restrict.directive';
import { SettingColorDirective } from '@shared/directives/setting-color.directive';
import { IfSettingDirective } from '@shared/directives/if-setting.directive';
import { SettingPipe } from '@shared/pipes/setting.pipe';
import { FileUrlPipe } from '@shared/pipes/file-url.pipe';
import { uniqueClientNameValidator } from '@shared/validators/unique-client-name-validator';
import { RoleSelectorComponent } from '@shared/components/role-selector/role-selector.component';
import { BaseDataFormComponent } from '@shared/components/base-data-form/base-data-form.component';
import { FileUploadComponent } from '@shared/components/file-upload/file-upload.component';
import { SignatureComponent } from '@shared/components/signature/signature.component';

@NgModule({
  declarations: [
    OfflineTableComponent,
    AppPatternRestrictDirective,
    SettingPipe,
    FileUrlPipe,
    AsteriksComponent,
    RoleSelectorComponent,
    SettingColorDirective,
    IfSettingDirective,
    BaseDataFormComponent,
    FileUploadComponent,
    SignatureComponent,
  ],
  imports: [
    FormsModule,
    PaginationModule.forRoot(),
    DataTableModule,
    TableModule,
    ReactiveFormsModule,
    EmendisTableModule.forRoot(TranslateService),
    FormModule,
    TooltipModule,
    MultiSelectModule,
    ButtonModule,
    CarouselModule,
    DropdownModule,
    ConfirmDialogModule,
    InputSwitchModule,
    SelectButtonModule,
    FormlyModule.forRoot({
      validators: [
        {
          name: 'uniqueClientName',
          validation: uniqueClientNameValidator()
        }
      ],
      validationMessages: [
        {
          name: 'required',
          message: 'Dit veld is verplicht'
        },
        {
          name: 'uniqueClientName',
          message: 'Deze naam is al in gebruik'
        },
        {
          name: 'email',
          message: 'Vul een geldig e-mailadres in.'
        },
        {
          name: 'password',
          message: 'Het wachtwoord moet minstens 6 karakters lang zijn, 1 hoofdletter, 1 nummer en een speciaal teken bevatten.'
        }
      ]
    }),
    ToastModule,
    TranslateModule,
    EmendisSharedModule.forRoot(MessageService, TranslateService),
    SignaturePadModule,
    NgxPermissionsModule
  ],
  exports: [
    OfflineTableComponent,
    EmendisTableModule,
    AsteriksComponent,
    AppPatternRestrictDirective,
    SettingPipe,
    FileUrlPipe,
    TranslatePipe,
    PaginationModule,
    MultiSelectModule,
    ButtonModule,
    CarouselModule,
    DropdownModule,
    ConfirmDialogModule,
    InputSwitchModule,
    SelectButtonModule,
    FormlyModule,
    FormModule,
    TranslateModule,
    SettingColorDirective,
    IfSettingDirective,
    NgxPermissionsModule,
    BaseDataFormComponent,
    FileUploadComponent,
    SignatureComponent,
  ]
})
export class SharedModule {
}
