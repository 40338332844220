import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '@shared/guards/auth.guard';
import { BootstrapGuard } from '@shared/guards/bootstrap.guard';
import { FooterNavigationLayoutComponent } from '@core/layouts/footerNavigationLayout.component';
import { PopupLayoutComponent } from '@core/layouts/popupLayout.component';
import { LoginLayoutComponent } from '@core/layouts/loginLayout.component';
import { RoleSelectorComponent } from '@shared/components/role-selector/role-selector.component';
import { SettingResolver } from '@shared/resolvers/setting.resolver';
import { ModuleResolver } from '@shared/resolvers/module.resolver';
import { ModuleTenantResolver } from '@shared/resolvers/module-tenant.resolver';
import { PermissionResolver } from '@shared/resolvers/permission.resolver';
import { NgxPermissionsGuard } from '@node_modules/ngx-permissions';
import { TranslationResolver } from '@shared/resolvers/translation.resolver';

const routes = [
  {
    path: 'auth',
    component: LoginLayoutComponent,
    loadChildren: './features/auth/auth.module#AuthModule'
  },
  {
    path: 'admin',
    canActivate: [BootstrapGuard, AuthGuard],
    canActivateChild: [AuthGuard],
    component: FooterNavigationLayoutComponent,
    resolve: {
      settings: SettingResolver,
      permissions: PermissionResolver,
      modules: ModuleResolver,
      translations: TranslationResolver,
      moduleTenants: ModuleTenantResolver
    },
    children: [
      {
        path: '',
        redirectTo: 'projects',
        pathMatch: 'full'
      },
      {
        path: 'tenants',
        loadChildren: './features/tenant/tenant.module#TenantModule',
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'tenant.manage',
            redirectTo: '/'
          }
        }
      },
      {
        path: 'users',
        loadChildren: './features/user/user.module#UserModule',
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'user.manage.tenant',
            redirectTo: '/'
          }
        }
      },
      {
        path: 'projects',
        loadChildren: './features/project/project.module#ProjectModule'
      },
      {
        path: 'project/:project',
        loadChildren: './features/inventory/inventory.module#InventoryModule'
      },
      {
        path: 'settings',
        loadChildren: './features/settings/settings.module#SettingsModule'
      },
      {
        path: 'relation-groups',
        loadChildren: './features/relation-group/relation-group.module#RelationGroupModule'
      },
      {
        path: 'translations',
        loadChildren: './features/translation/translation.module#TranslationModule',
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'translation.manage.tenant',
            redirectTo: '/'
          }
        }
      },
      {
        path: 'roles',
        loadChildren: './features/role/role.module#RoleModule',
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'role.manage.tenant',
            redirectTo: '/'
          }
        }
      },
      {
        path: 'base-data',
        loadChildren: './features/base-data/base-data.module#BaseDataModule',
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'base-data-value.manage.tenant',
            redirectTo: '/'
          }
        }
      },
      {
        path: 'clients',
        loadChildren: './features/client/client.module#ClientModule'
      },
      {
        path: 'actions',
        loadChildren: './features/action-lists/action-lists.module#ActionListsModule'
      },
      {
        path: 'shipping-manifest',
        loadChildren: './features/shipping-manifest/shipping-manifest.module#ShippingManifestModule',
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['shipping_manifest.index']
          }
        }
      }
    ]
  },
  {
    path: 'admin',
    canActivate: [BootstrapGuard, AuthGuard],
    canActivateChild: [AuthGuard],
    component: PopupLayoutComponent,
    outlet: 'popup',
    children: [
      {
        path: 'users',
        loadChildren: './features/user/user.module#UserModule',
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'user.manage.tenant',
            redirectTo: '/'
          }
        }
      },
      {
        path: 'tenants',
        loadChildren: './features/tenant/tenant.module#TenantModule',
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'tenant.manage',
            redirectTo: '/'
          }
        }
      },
      {
        path: 'project',
        loadChildren: './features/inventory/inventory.module#InventoryModule'
      },
      {
        path: 'clients',
        loadChildren: './features/client/client.module#ClientModule'
      },
      {
        path: 'relation-groups',
        loadChildren: './features/relation-group/relation-group.module#RelationGroupModule'
      },
      {
        path: 'role/select',
        component: RoleSelectorComponent
      },
      {
        path: 'actions',
        loadChildren: './features/action-lists/action-lists.module#ActionListsModule'
      },
      {
        path: 'translations',
        loadChildren: './features/translation/translation.module#TranslationModule',
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'translation.manage.tenant',
            redirectTo: '/'
          }
        }
      },
      {
        path: 'roles',
        loadChildren: './features/role/role.module#RoleModule',
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'role.manage.tenant',
            redirectTo: '/'
          }
        }
      },
      {
        path: 'base-data',
        loadChildren: './features/base-data/base-data.module#BaseDataModule',
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'base-data-value.manage.tenant',
            redirectTo: '/'
          }
        }
      },
      {
        path: 'shipping-manifest',
        loadChildren: './features/shipping-manifest/shipping-manifest.module#ShippingManifestModule'
      }
    ]
  },
  {
    path: '',
    redirectTo: 'admin/projects',
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: 'admin'
  }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
