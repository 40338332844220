import { DomainModel } from '@domain/domain.model';

import * as uuid from 'uuid/v4';

export class InventoryItem extends DomainModel {
  // Configuration
  public entity = 'inventory-item';
  public table = 'inventory_items';
  public schema = 'id, remote_id, inventory_id, project_id';
  public sync = true;
  public id: string;

  // Fields
  public inventory_id: string;
  public project_id: string;
  public amount: number;
  public max_amount?: number;
  public name?: string;
  public volume?: number;
  public meterbox?: number;
  public remarks?: string;
  public storage_number?: string;
  public cargo_container?: string;
  public assemble?: boolean;
  public disassemble?: boolean;
  public images?: string[];
  public base_data_values?: string[];
  public isNew: boolean;
  public new_base_data_values?: any[];

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }

  public getData(): any {
    return {
      id: this.id,
      inventory_id: this.inventory_id,
      project_id: this.project_id,
      amount: this.amount || 0,
      max_amount: this.max_amount || null,
      storage_number: this.storage_number || '',
      cargo_container: this.cargo_container || '',
      name: this.name || '',
      volume: this.volume || 0,
      remarks: this.remarks || '',
      assemble: this.assemble,
      disassemble: this.disassemble,
      images: this.images || [],
      base_data_values: this.base_data_values || [],
      isNew: this.isNew || true,
      new_base_data_values: this.new_base_data_values || []
    };
  }
}
