import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { SettingService } from '@shared/services/setting.service';
import { Subject } from '@node_modules/rxjs';
import { Setting } from '@domain/models/setting.model';
import { takeUntil } from '@node_modules/rxjs/internal/operators';

@Directive({
  selector: '[appIfSetting]'
})
export class IfSettingDirective implements OnInit, OnDestroy {
  @Input() appIfSetting: string;
  private destroy$: Subject<void>;

  constructor(private element: ElementRef, private settingService: SettingService) {
    this.destroy$ = new Subject();
  }

  public ngOnInit(): void {
    this.changeDisplay();
    this.settingService.settingsChanged$
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(() => {
      this.changeDisplay();
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }

  /**
   * Display the element based on setting
   */
  private changeDisplay(): void {
    this.element.nativeElement.style.display = Setting.getValue(this.appIfSetting) ? 'auto' : 'none';
  }
}
