import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { File } from '@shared/models/file.model';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  @Input()
  public showPreview: boolean;
  @Input()
  public multiple: boolean;
  @Input()
  public fileType: string;
  @Input()
  public files: File[];
  @Output()
  public onFileChange: EventEmitter<File[]>;
  @Output()
  public onFileDelete = new EventEmitter<File>();
  @Output()
  public onFileAdd = new EventEmitter<File>();

  public imageUrls: (string | SafeUrl)[];
  private mimeType: string;
  private groupedFileType: string;

  /**
   * Constructor
   *
   * @param confirmationService: ConfirmationService
   * @param translateService: TranslateService
   */
  constructor(
    private confirmationService: ConfirmationService,
    private translateService: TranslateService
  ) {
    this.onFileChange = new EventEmitter<File[]>();
    this.imageUrls = [];
    this.showPreview = true;
    this.fileType = 'all';
    this.groupedFileType = 'any';
    this.files = [];
  }

  ngOnInit() {
    this.setMimeType();
    this.setGroupedFileType();
  }

  /**
   * On change of files
   *
   * @param event: any (files)
   * @return void
   */
  public onChange(event: any): void {
    if (event.target.files) {

      if (!this.files || this.files === undefined) {
        this.files = [];
      }

      for (const file of event.target.files) {
        const reader = new FileReader();

        reader.onload = (onLoadEvent: any) => {
          this.imageUrls.push(reader.result);
          const newFile: File = {
            data: reader.result,
            filename: file.name,
            extension: '',
            mimeType: file.type
          };

          this.files = [...this.files, newFile];
          this.onFileAdd.emit(newFile);
          this.onFileChange.emit(this.files);
        };

        reader.readAsDataURL(file);
      }
    }
  }

  /**
   * Delete the image from files list
   *
   * @param image: File
   * @return Promise<void>
   */
  public async onImageDeleteClick(image: File): Promise<void> {
    this.confirmationService.confirm({
      message: this.translateService.instant('confirm.delete-image.message'),
      header: this.translateService.instant('confirm.title'),
      icon: 'fa fa-question-circle',
      accept: () => {
        this.onFileDelete.emit(image);

        this.files = this.files.filter((file) => {
          if (file.id) {
            return file.id !== image.id;
          } else {
            return file.data !== image.data && file.filename !== image.filename;
          }
        });

        this.onFileChange.emit(this.files);
      }
    });
  }

  /**
   * Set the group file type based on allowed file type
   */
  private setGroupedFileType() {
    switch (this.fileType) {
      case 'image':
      case 'audio':
      case 'video':
      case 'media':
        this.groupedFileType = this.fileType;
        break;
      case 'doc':
      case 'pdf':
      case 'rtf':
      case 'write':
      case 'text':
        this.groupedFileType = 'doc';
        break;
      case 'zipped':
      case 'bz':
      case 'gz':
      case 'tgz':
      case 'zip':
        this.groupedFileType = 'zip';
        break;
      case 'office':
      case 'excel':
      case 'powerpoint':
      case 'project':
      case 'word':
      case 'visio':
        this.groupedFileType = 'office';
        break;
      case 'iwork':
      case 'keynote':
      case 'pages':
      case 'numbers':
        this.groupedFileType = 'iwork';
        break;
      case '':
      case 'all':
      case null:
        this.groupedFileType = 'any';
        break;
      default:
        this.groupedFileType = 'none';
        break;
    }
  }

  /**
   * Set the mime type based on allowed file type
   *
   * @param fileType string|null
   * @return null|undefined|string
   */
  private setMimeType(fileType: string = null) {
    if (fileType === null) {
      fileType = this.fileType;
    }

    switch (fileType) {
      case 'pdf':
      case 'rtf':
      case 'zip':
        this.mimeType = `application/${fileType}`;
        break;
      case 'bz':
      case 'gz':
        this.mimeType = `application/x-${fileType}ip`;
        break;
      case 'tgz':
        this.mimeType = 'application/x-compressed';
        break;
      case 'write':
        this.mimeType = `application/ms${fileType}`;
        break;
      case 'word':
        this.mimeType = `application/ms${fileType},application/vnd.openxmlformats-officedocument.wordprocessingml.document`;
        break;
      case 'excel':
        this.mimeType = `application/vnd.ms-${fileType},application/${fileType},application/x-${fileType},application/x-ms${fileType},` +
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        break;
      case 'powerpoint':
        this.mimeType = `application/vnd.ms-${fileType},application/ms${fileType},application/x-ms${fileType},` +
          'application/vnd.openxmlformats-officedocument.presentationml.presentation';
        break;
      case 'project':
        this.mimeType = `application/vnd.ms-${fileType},application/x-${fileType}`;
        break;
      case 'visio':
        this.mimeType = `application/x-${fileType}`;
        break;
      case 'keynote':
        this.mimeType = `application/vnd.apple.${fileType},application/x-iwork-keynote-sffkey`;
        break;
      case 'pages':
        this.mimeType = `application/vnd.apple.${fileType},application/x-iwork-pages-sffpages`;
        break;
      case 'numbers':
        this.mimeType = `application/vnd.apple.${fileType},application/x-iwork-numbers-sffnumbers`;
        break;
      case 'image':
      case 'audio':
      case 'video':
        this.mimeType = `${fileType}/*`;
        break;
      case 'text':
        this.mimeType = `${fileType}/plain`;
        break;
      case 'office':
        this.mimeType = `${this.setMimeType('word')},${this.setMimeType('excel')},${this.setMimeType('powerpoint')},` +
          `${this.setMimeType('project')},${this.setMimeType('visio')}`;
        break;
      case 'iwork':
        this.mimeType = `${this.setMimeType('keynote')},${this.setMimeType('pages')},${this.setMimeType('numbers')}`;
        break;
      case 'media':
        this.mimeType = `${this.setMimeType('image')},${this.setMimeType('audio')},${this.setMimeType('video')}`;
        break;
      case 'zipped':
        this.mimeType = `${this.setMimeType('zip')},${this.setMimeType('tgz')},${this.setMimeType('bz')},${this.setMimeType('gz')}`;
        break;
      case 'doc':
        this.mimeType = `${this.setMimeType('pdf')},${this.setMimeType('rtf')},${this.setMimeType('write')},${this.setMimeType('text')}`;
        break;
      case '':
      case 'all':
      case null:
        this.mimeType = '*/*';
        break;
      default:
        this.mimeType = undefined;
        break;
    }

    return this.mimeType;
  }
}
