import { DomainModel } from '@domain/domain.model';
import { from, Observable, of } from '@node_modules/rxjs';
import { map } from '@node_modules/rxjs/internal/operators';
import { SelectItem } from '@node_modules/primeng/components/common/selectitem';
import { Client } from '@domain/models/client.model';

import * as uuid from 'uuid/v4';

export class BaseDataValue extends DomainModel {
  // Configuration
  public entity = 'base-data-value';
  public table = 'base_data_value';
  public schema = 'id, base_data_key, parent_id';
  public sync = true;

  // Fields
  public base_data_key: string;
  public id: string;
  public parent_id: string;

  public translations?: any;

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }

  /**
   * Get the base-data-values by base-data-key
   *
   * @param key
   *
   * @return list of base-data-values
   */
  public static getByBaseDataKey(key: string): Promise<BaseDataValue[]> {
    return BaseDataValue.query.where({ base_data_key: key }).toArray();
  }

  /**
   * Get the base-data-values by parent_id
   *
   * @param parentId
   *
   * @return list of base-data-values
   */
  public static async getByParentId(parentId: string): Promise<BaseDataValue[]> {
    return await BaseDataValue.query.where({ parent_id: parentId }).toArray();
  }

  /**
   * Return base data into Select items by key
   *
   * @param key
   */
  public static async getBaseDataList(key: string): Promise<SelectItem[]> {
    const values = await BaseDataValue.getByBaseDataKey(key);

    return values.map((value) => {
      return { label: value.id, value: value.id };
    });
  }
}
