import { Component, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@emendis/auth';
import { ToastService } from '@emendis/shared';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { emailValidator, passwordValidator } from '@emendis/formly';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: [
    '../login/login.component.scss',
    './reset-password.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class ResetPasswordComponent {
  public user: {
    token: string,
    email: string,
    passwordFields: {
      password: string,
      password_confirmation: string
    }
  };
  public formGroup: FormGroup;
  public fields: FormlyFieldConfig[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private toastService: ToastService
  ) {
    this.formGroup = new FormGroup({});
    this.user = {
      token: '',
      email: '',
      passwordFields: {
        password: '',
        password_confirmation: ''
      }
    };

    this.createForm();

    this.route.params.subscribe((params) => {
      if (params['token']) {
        this.user.token = params['token'];
      }
    });
  }

  public submit(): void {
    if (this.formGroup.valid) {
      const data = { ...this.user, ...this.user.passwordFields };

      this.authService.resetPassword(data).subscribe(() => {
        this.toastService.success('Wachtwoord gereset', 'U wordt doorgestuurd naar de login pagina.');

        this.router.navigate(['auth/login']);
      }, (error) => {
        this.toastService.handleError(error, 'Wachtwoord resetten mislukt', 'Er is een fout opgetreden.');
      });
    } else {
      this.toastService.error('Wachtwoord resetten mislukt', 'Controleer uw invoer.');
    }
  }

  /**
   * Create fields for reset password form
   */
  private createForm(): void {
    this.fields = [
      {
        type: 'input',
        key: 'email',
        templateOptions: {
          translateLabel: 'Gebruikersnaam',
          placeholder: 'Vul hier uw gebruikersnaam in',
          type: 'email',
          required: true
        },
        validators: {
          validation: [emailValidator]
        }
      },
      {
        key: 'passwordFields',
        validators: {
          fieldMatch: {
            expression: (control) => {
              const value = control.value;

              return value.password_confirmation === value.password
                || (!value.password_confirmation || !value.password);
            },
            message: 'De wachtwoorden komen niet overeen',
            errorPath: 'password_confirmation'
          }
        },
        fieldGroup: [
          {
            key: 'password',
            type: 'input',
            templateOptions: {
              type: 'password',
              translateLabel: 'Nieuw wachtwoord',
              placeholder: 'Voer een nieuw wachtwoord in',
              required: true
            },
            validators: {
              validation: [passwordValidator]
            }
          },
          {
            key: 'password_confirmation',
            type: 'input',
            templateOptions: {
              type: 'password',
              translateLabel: 'Nieuw wachtwoord bevestigen',
              placeholder: 'Voer nogmaals uw nieuwe wachtwoord in',
              required: true
            }
          }
        ]
      }
    ];
  }
}
