import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { from } from 'rxjs';

import { Store } from '@domain/store';

@Injectable()
export class BootstrapGuard implements CanActivate {
  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const store = Store.getStore();
    return await store.setup();
  }
}
