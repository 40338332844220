import { Injectable } from '@angular/core';
import { ApiService } from '@emendis/api';

import { Setting as SettingDomain } from '@domain/models/setting.model';

import { Setting } from '@shared/models/setting.model';
import { Observable, Subject } from 'rxjs';
import { catchError, map, tap } from '@node_modules/rxjs/internal/operators';

@Injectable()
export class SettingService extends ApiService<Setting> {
  protected endpoint = 'setting';
  public settingsChanged$: Subject<void> = new Subject();

  public async loadSettings(): Promise<void> {
    const settings = await this.apiHttp.get('/role/setting')
      .pipe(
        map((response: any) => response.data),
        catchError(async () => {
          await SettingDomain.loadSettings();
          this.settingsChanged$.next();
          this.setGlobalStyles();

          return false;
        })
      ).toPromise();

    if (settings) {
      await SettingDomain.query.clear();

      const settingRecords = [];
      for (const key in settings) {
        if (settings.hasOwnProperty(key)) {
          settingRecords.push({ id: key, value: settings[key] });
        }
      }

      await SettingDomain.query.bulkAdd(settingRecords);
      await SettingDomain.loadSettings();
      this.settingsChanged$.next();
      this.setGlobalStyles();
    }
  }

  /**
   * Set a setting for a tenant
   */
  public setSetting(settingId: string, data: { value: any, tenant_id?: string }): Observable<any> {
    if (typeof data.value !== 'string') {
      data.value = JSON.stringify(data.value);
    }

    this.settingsChanged$.next();

    return this.apiHttp.post(`/setting/set/${settingId}`, data).pipe(tap((response) => {
      SettingDomain.query.put({ id: response.data.key, value: response.data.value });
      SettingDomain.loadSettings().then(() => {
        this.settingsChanged$.next();
        this.setGlobalStyles();
      });
    }));
  }

  /**
   * Get a setting value
   *
   * @param setting
   * @return any
   */
  public getSettingValue(setting: string): any {
    if (typeof SettingDomain.getBooleanValueByName(setting) === 'boolean') {
      return SettingDomain.getBooleanValueByName(setting);
    }
  }

  /**
   * Get a setting value
   *
   * @param setting
   * @return any
   */
  public getSettingValueString(setting: string): any {
      return SettingDomain.getValue(setting);
  }

  /**
   * Set global styles
   */
  public setGlobalStyles(): void {
    if (SettingDomain.settings) {
      const colorSettings = SettingDomain.settings.filter(setting => (setting.id as string).includes('color'));

      for (const setting of colorSettings) {
        let key = setting.id as string;

        if (key.includes('general.tenant_')) {
          key = key.split('general.tenant_')[1];
        }

        document.documentElement.style.setProperty(`--${key}`, setting.value as string);
      }
    }
  }
}
