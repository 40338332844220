import { DomainModel } from '@domain/domain.model';

import * as uuid from 'uuid/v4';
import { DeliveryItem } from './delivery-item.model';

export class Delivery extends DomainModel {
  // Configuration
  public entity = 'delivery';
  public table = 'deliveries';
  public schema = 'id, remote_id, deliverable_id';
  public sync = true;
  public id: string;

  // Fields
  public deliverable_id?: string; // this is shipping_manifest_id
  public status_base_data_value_id?: string;
  public comments?: string;
  public delivered_at?: Date | string;

  public images?: File[];
  public signature_unloader_delivery_image?: string;
  public signature_client_delivery_image?: string;

  public items?: DeliveryItem[];

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }

  public async init(): Promise<void> {
    await this.loadItems();
  }

  private async loadItems(): Promise<void> {
    this.items = [];
    const items = await DeliveryItem.query.where('inventory_delivery_id').equals(this.id).toArray();

    for (const item of items) {
      await item.init();
      this.items.push(item);
    }
  }

  public getData(): any {
    return {
      id: this.id,
      deliverable_id: this.deliverable_id || null,
      status_base_data_value_id: this.status_base_data_value_id || '',
      comments: this.comments || '',
      images: this.objectsToArray(this.images) || [],
      signature_unloader_delivery_image: this.signature_unloader_delivery_image || null,
      signature_client_delivery_image: this.signature_client_delivery_image || null,
      items: this.items || []
    };
  }
}
