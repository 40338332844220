import { DomainModel } from '@domain/domain.model';

import * as uuid from 'uuid/v4';

export class ShippingManifestItem extends DomainModel {
  // Configuration
  public entity = 'shippingmanifest-item';
  public table = 'shippingmanifest_items';
  public schema = 'id, remote_id, shipping_manifest_id, inventory_item_id';
  public sync = true;
  public id: string;

  // Fields
  public shipping_manifest_id?: string;
  public inventory_item_id?: string;
  public amount?: number;

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }

  public getData(): any {
    return {
      id: this.id,
      shipping_manifest_id: this.shipping_manifest_id,
      inventory_item_id: this.inventory_item_id,
      amount: this.amount
    };
  }
}
