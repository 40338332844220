import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Client } from '@domain/models/client.model';
import { from, Observable } from '@node_modules/rxjs';

export function uniqueClientNameValidator(): AsyncValidatorFn {
  return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
    return from(Client.query.where('name').equals(control.value).toArray()).map(
      clients => {
        return (clients && clients.length > 0) ? { 'uniqueName': true } : null;
      }
    );
  };
}
