import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { AuthService } from '@emendis/auth';
import { TranslationService } from '@shared/services/translation.service';
import { ToastService } from '@emendis/shared';
import { TranslateService } from '@ngx-translate/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { emailValidator } from '@emendis/formly';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent {
  public formGroup: FormGroup;
  public fields: FormlyFieldConfig[];
  public user: { username: string, password: string };
  public loginError: boolean = false;
  public resetError: boolean = false;
  public resetSuccess: boolean = false;
  private loginBusy: boolean = false;

  public constructor(
    private authService: AuthService,
    private router: Router,
    private translateService: TranslateService,
    private translationService: TranslationService,
    private toastService: ToastService
  ) {
    this.formGroup = new FormGroup({});

    this.user = {
      username: '',
      password: ''
    };

    this.loginError = false;
    this.resetError = false;
    this.resetSuccess = false;
    this.loginBusy = false;

    this.createForm();
  }

  /**
   * Submit login form
   */
  public onSubmit(): void {
    if (this.loginBusy) {
      return;
    }

    this.loginError = false;
    this.resetError = false;

    if (this.formGroup.valid) {
      this.loginBusy = true;

      this.authService.login(this.user.username, this.user.password).subscribe((result) => {
        if (result) {
          this.router.navigate(['/admin']);
        }

        this.loginBusy = false;
      }, (error) => {
        // Show login error message
        this.loginError = true;
        this.loginBusy = false;
      });
    }
  }

  /**
   * Request a new password
   */
  public resetPassword(): void {
    if (this.user.username !== '') {
      this.authService.recoverPassword(this.user.username).subscribe(result => {
        if (result) {
          this.resetError = false;
          this.resetSuccess = true;
        }
      }, (error) => {
        this.toastService.handleError(error, '', '');
      });
    } else {
      // Show login error message
      this.resetError = true;
    }
  }

  /**
   * Create fields for login form
   */
  private createForm(): void {
    this.fields = [
      {
        type: 'input',
        key: 'username',
        templateOptions: {
          translateLabel: 'Gebruikersnaam',
          placeholder: 'Vul hier uw gebruikersnaam in',
          type: 'email',
          required: true
        },
        validators: {
          validation: [emailValidator]
        }
      },
      {
        type: 'input',
        key: 'password',
        templateOptions: {
          translateLabel: 'Wachtwoord',
          placeholder: 'Vul hier uw wachtwoord in',
          type: 'password',
          required: true
        }
      }
    ];
  }
}
