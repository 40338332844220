import { DomainModel } from '@domain/domain.model';
import { SelectItem } from '@node_modules/primeng/components/common/selectitem';

export class Specialty extends DomainModel {
  public static specialties: Specialty[];

  // Configuration
  public entity = 'specialty';
  public table = 'specialties';
  public schema = '++id, remote_id, project_type';
  public sync = true;

  // Fields
  public project_type: string;
  public name: string;
  public has_hours: boolean;
  public cost_rate: number;
  public has_remarks: boolean;
  public has_date: boolean;
  public applicable: boolean;
  public details: string;
  public hours_estimate: number;
  public start: string;

  // Constructor
  constructor(attributes) {
    super(attributes);
  }

  public static listByProjectType(projectType: string) {
    return Specialty.query.where('project_type').equals(projectType).toArray();
  }

  /**
   * Load current specialties in static cache
   */
  public static async loadSpecialties(): Promise<void> {
    Specialty.specialties = await Specialty.query.toArray();
  }

  /**
   * Return all specialties
   *
   * @return Promise<Specialty[]>
   */
  public static async getSpecialties(): Promise<Specialty[]> {
    return await await Specialty.query.toArray();
  }
}
