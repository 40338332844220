import { Injectable } from '@angular/core';
import { ApiService } from '@emendis/api';
import { Module } from '@shared/models/module.model';
import { Specialty, Specialty as SpecialtyDomain } from '@domain/models/specialty.model';
import { map } from '@node_modules/rxjs/internal/operators';

@Injectable({
  providedIn: 'root'
})
export class SpecialtyService extends ApiService<Module> {
  public endpoint = 'inventory-specialty/specialty';

  /**
   * Get the specialties of the user and store them in the indexedDB
   *
   * @return Promise<void>
   */
  public async loadSpecialties(): Promise<void> {
    const specialties = await this.apiHttp.get(`/${this.endpoint}/sync`).pipe(map((response: { data: Specialty[] }) => response.data)).toPromise();
    await SpecialtyDomain.query.clear();
    await SpecialtyDomain.query.bulkAdd(specialties);
  }
}
