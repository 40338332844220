import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileService } from '@shared/services/file.service';
import { Observable } from '@node_modules/rxjs';
import { map } from '@node_modules/rxjs/internal/operators';

@Pipe({
  name: 'fileUrl'
})
export class FileUrlPipe implements PipeTransform {

  constructor(
    private sanitizer: DomSanitizer,
    private fileApi: FileService) {
  }

  public transform(id: string): Observable<SafeUrl> {
    return this.fileApi.getFile(id).pipe(map((response) => {
      return URL.createObjectURL(response.file);
    }));
  }
}
