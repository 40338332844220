import { TranslateLoader } from '@node_modules/@ngx-translate/core';
import { Observable } from '@node_modules/rxjs';
import { Translation } from '@domain/models/translation.model';

export class TranslationLoader implements TranslateLoader {
  /**
   * Get the translations for the given language
   *
   * @param lang
   */
  public getTranslation(lang: string): Observable<any> {

    return new Observable((resolver) => {
      Translation.query.get(lang).then((result) => {
        resolver.next(result.value);
      }, err => {
        resolver.next({});
      }).catch(() => {
        resolver.next({});
      });
    });
  }
}
