import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from '@node_modules/rxjs';
import { TranslationService } from '@shared/services/translation.service';
import { QueryOptions, DataService } from '@shared/services/data.service';
import { Translation } from '@domain/models/translation.model';
import { AuthService } from '@emendis/auth';
import { TranslateService } from '@ngx-translate/core';
import { isUnavailable } from '@shared/services/util.service';

@Injectable({ providedIn: 'root' })
export class TranslationResolver implements Resolve<Observable<any>> {
  constructor(
    private translationService: TranslationService,
    private dataService: DataService,
    private translateService: TranslateService,
    private authService: AuthService
  ) {
  }

  /**
   * Make sure the translations are loaded before accessing the route
   *
   * @param route
   * @param state
   *
   * @return Promise<any>
   */
  public async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    const translations = await this.dataService.get('translations', new QueryOptions({ usePaging: false }), '/role/translations');

    if (isUnavailable(translations) === true) {
      return this.translationService.loadTranslations();
    }

    Translation.loadTranslations();

    const loggedInUser = this.authService.getUser();
    this.translateService.setDefaultLang('en');

    if (loggedInUser && loggedInUser.locale) {
      const language = loggedInUser.locale.code;
      this.translateService.use(language);
    }

    return;
  }
}
