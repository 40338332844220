import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService } from '@emendis/api';
import { Observable, Subject } from '@node_modules/rxjs';
import { catchError, map, tap } from '@node_modules/rxjs/internal/operators';
import { Translation as TranslationDomain } from '@domain/models/translation.model';
import { AuthService } from '@emendis/auth';
import { TranslateService } from '@node_modules/@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslationService extends ApiService<any> {
  protected endpoint = 'role/translation';

  constructor(private api: ApiHttpService,
              private translateService: TranslateService,
              private authService: AuthService) {
    super(api);
  }

  /**
   * Get the translations for the current user
   *
   * @return Observable<any>
   */
  public getTranslations(): Observable<any> {
    return this.apiHttp.get(`/${this.endpoint}`).pipe(
      map((response: { data: any }) => response.data),
      tap(async response => {
        for (const key in response) {
          if (response.hasOwnProperty(key)) {
            TranslationDomain.query.put({ id: key, value: response[key] });
          }
        }
      })
    );
  }

  /**
   * Load translations and set default or current users language
   *
   * @return Promise<void>
   */
  public async loadTranslations(): Promise<void> {
    const translations = await this.apiHttp.get(`/${this.endpoint}`)
      .pipe(
        map((response: any) => response.data),
        catchError(async () => {
          await TranslationDomain.loadTranslations();

          return false;
        })
      ).toPromise();

    if (translations) {
      await TranslationDomain.query.clear();

      const translationRecords = [];
      for (const key in translations) {
        if (translations.hasOwnProperty(key)) {
          translationRecords.push({ id: key, value: translations[key] });
        }
      }

      await TranslationDomain.query.bulkAdd(translationRecords);
      await TranslationDomain.loadTranslations();

      const loggedInUser = this.authService.getUser();
      this.translateService.setDefaultLang('en');

      if (loggedInUser && loggedInUser.locale) {
        const language = loggedInUser.locale.code;
        this.translateService.use(language);
      }
    }
  }
}
