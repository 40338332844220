import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: 'loginLayout.component.html',
  styleUrls: ['./loginLayout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginLayoutComponent {

}
