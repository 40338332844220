import { DomainModel } from '@domain/domain.model';
import { OrderPickingItem } from './orderpicking-item.model';

import * as uuid from 'uuid/v4';

export class OrderPicking extends DomainModel {
  // Configuration
  public entity = 'orderpicking';
  public table = 'orderpickings';
  public schema = 'id, remote_id, project_id';
  public sync = true;
  public id: string;
  public date: Date | string;

  // Fields
  public project_id?: string;
  public status?: number;
  public cargo_container?: string;

  // public items: {inventory_item_id: string, amount: number, maxSliderAmount: number}[];
  public items: any[];

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }

  public async init() {
    const newItems = [];
    const items = await OrderPickingItem.query.where('orderpicking_id').equals(this.id).toArray();

    items.forEach((item) => {
      item.cargo_container = this.cargo_container;

      newItems.push(item);
    });
    
    this.items = newItems;
  }

  public getData(): any {
    return {
      id: this.id,
      project_id: this.project_id,
      status: this.status,
      items: this.items || [],
      cargo_container: this.cargo_container || null,
      date: this.date
    };
  }
}
