import { DomainModel } from '@domain/domain.model';
import { Client } from '@domain/models/client.model';
import { Address } from '@domain/models/address.model';
import { Activity } from '@domain/models/activity.model';
import { ProjectActivity } from '@domain/models/project-activity.model';
import { ProjectSpecialty } from '@domain/models/project-specialty.model';
import { Inventory } from '@domain/models/inventory.model';
import { Quotation } from '@domain/models/quotation.model';
import { SelectItem } from 'primeng/api';
import { User } from '@domain/models/user.model';
import { Receipt } from '@domain/models/receipt.model';
import { Contact } from '@domain/models/contact.model';

import * as uuid from 'uuid/v4';
import { OrderPicking } from './orderpicking.model';

export class Project extends DomainModel {
  // Configuration
  public entity = 'project';
  public table = 'projects';
  public schema = 'id';
  public sync = true;
  public id: string;

  // Fields
  public _original: any;
  public is_new = false;
  public is_changed = false;

  public type = 'private';
  public projectType?: string;
  public status = '677109b0-5513-11e9-8b60-25cee0667000';
  public status_base_data_value_id = '677109b0-5513-11e9-8b60-25cee0667000';
  public inventory_client_id?: string;
  public clientName?: string;
  public accountmanager_id?: number;
  public accountmanagerName?: string;
  public executor_id?: number;
  public reference_nr?: string;
  public new_housing_project?: boolean;
  public own_description_activities?: string;
  public client_description_activities?: string;
  public description?: string;
  public remarks?: string;

  public client = new Client({});
  public accountmanager = new User({});

  public accountmanagers?: string;

  public addresses: Address[] = [];
  public contacts: Contact[] = [];
  public specialties: ProjectSpecialty[] = [];
  public activities: ProjectActivity[] = [];
  public inventories: Inventory[] = [];
  public quotations: Quotation[] = [];
  public receipts: Receipt[] = [];
  public orderpickings: OrderPicking[] = [];

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }

  public async init() {
    // Set project type name
    switch (this.type) {
      case 'business':
        this.projectType = 'Zakelijk';
        break;
      case 'private':
        this.projectType = 'Particulier';
        break;
      default:
        this.projectType = '?';
        break;
    }

    // Set relations
    if (this.inventory_client_id) {
      this.client = await Client.query.get(this.inventory_client_id);
      this.clientName = this.client ? this.client.name : '';
    }

    if (this.accountmanager_id) {
      this.accountmanager = await User.query.get(this.accountmanager_id);
      this.accountmanagerName = this.accountmanager ? this.accountmanager.name : '';
    }

    await this.loadAddresses();
    await this.loadContacts();
  }

  public async loadActivities() {
    if (!this.id) {
      return;
    }

    this.activities = await ProjectActivity.query
      .where('project_id')
      .equals(this.id)
      .toArray();

    for (const activity of this.activities) {
      await activity.init();
    }

    // Fill activities if not available yet
    if (!this.activities || this.activities.length === 0) {
      const activities = await Activity.query
        .where('project_type')
        .equals(this.type)
        .toArray();

      for (const activity of activities) {
        await activity.init();
        const projectActivity = new ProjectActivity({
          activity_id: activity.id,
          project_id: this.id
        });
        await projectActivity.init();
        this.activities.push(projectActivity);
      }
    }
  }

  public async loadOrderpickings() {
    if (!this.id) {
      return;
    }

    const pickings = await OrderPicking.query
      .where('project_id')
      .equals(this.id)
      .toArray();

    this.orderpickings = [];

    for (const orderpicking of pickings) {
      await orderpicking.init();
      this.orderpickings.push(orderpicking);
    }
  }

  public async loadSpecialties() {
    if (!this.id) {
      return;
    }

    const specialties = await ProjectSpecialty.query
      .where('project_id')
      .equals(this.id)
      .toArray();

    this.specialties = [];

    for (const specialty of specialties) {
      await specialty.init();
      this.specialties.push(specialty);
    }
  }

  public async loadInventories() {
    const inventories = await Inventory.query
      .where('project_id')
      .equals(this.id)
      .toArray();

    this.inventories = [];

    for (const inventory of inventories) {
      await inventory.init();
      this.inventories.push(inventory);
    }
  }

  public async loadQuotations() {
    const quotations = await Quotation.query
      .where('project_id')
      .equals(this.id)
      .toArray();

    this.quotations = [];

    for (const quotation of quotations) {
      await quotation.init();
      this.quotations.push(quotation);
    }
  }

  /**
   * Load project addresses
   *
   * @return Promise<void>
   */
  public async loadAddresses(): Promise<void> {
    const addresses = await Address.query
      .where('project_id')
      .equals(this.id)
      .toArray();

    this.addresses = [];

    for (const address of addresses) {
      await address.init();
      this.addresses.push(address);
    }
  }

  /**
   * Load project contacts
   *
   * @return Promise<void>
   */
  public async loadContacts(): Promise<void> {
    const contacts = await Contact.query
      .where('project_id')
      .equals(this.id)
      .toArray();

    this.contacts = [];

    for (const contact of contacts) {
      await contact.init();
      this.contacts = [...this.contacts, contact];
    }
  }

  /**
   * Load project receipts
   *
   * @return Promise<void>
   */
  public async loadReceipts(): Promise<void> {
    const receipts = await Receipt.query
      .where('project_id')
      .equals(this.id)
      .toArray();

    this.receipts = [];

    for (const receipt of receipts) {
      await receipt.init();
      this.receipts.push(receipt);
    }
  }

  /**
   * Retrieves the total price from described specialty
   *
   * @param specialtyName
   * @returns number
   */
  public async getSpecialtyTotalPrice(specialtyName: string): Promise<number> {
    if (!this.specialties || this.specialties.length === 0) {
      await this.loadSpecialties();
    }

    // Find the specialty and return the price
    const specialty = this.specialties.find((specialtyItem) => specialtyItem.specialty.name === specialtyName);

    if (specialty && specialty.applicable) {
      // Determine whether the specialty has been toggled true, if not, don't show it and return -1
      if (specialty.specialty.has_hours) {
        return (specialty.hours_estimate ? specialty.hours_estimate : 0) * specialty.specialty.cost_rate;
      } else {
        // If specialty does not contain hours, only display the initial cost rate
        return specialty.specialty.cost_rate;
      }
    } else {
      return null;
    }
  }

  public hasActivities() {
    return this.activities && this.activities.some(activity => activity.applicable);
  }

  public hasSpecialties() {
    return this.specialties && this.specialties.some(specialty => specialty.applicable);
  }

  /**
   * GetData
   *
   * @return any
   */
  public getData(): any {
    return {
      id: this.id,
      inventory_client_id: this.inventory_client_id,
      type: this.type || '',
      status: this.status_base_data_value_id || '',
      status_base_data_value_id: this.status_base_data_value_id || '',
      reference_nr: this.reference_nr || '',
      new_housing_project: this.new_housing_project || false,
      own_description_activities: this.own_description_activities || '',
      client_description_activities: this.client_description_activities || '',
      executor_id: this.executor_id,
      accountmanager_id: this.accountmanager_id,
      address: this.addresses || [],
      contact: this.contacts || [],
      orderpickings: this.orderpickings || []
    };
  }

  /**
   * Returns the project status list options
   */
  public static getStatusList(): SelectItem[] {
    return [
      { label: 'Nieuw', value: 'new' },
      { label: 'Booked', value: 'booked' },
      { label: 'Pending', value: 'pending' },
      { label: 'Lost', value: 'lost' },
      { label: 'Inactive', value: 'inactive' }
    ];
  }

  /**
   * Returns the project type list options
   */
  public static getTypeList(): SelectItem[] {
    return [
      { label: 'Particulier', value: 'private' },
      { label: 'Zakelijk', value: 'business' }
    ];
  }

  /**
   * Returns the status name by value
   */
  public static getStatusName(status: string): string {
    if (status) {
      return 'base-data.' + status;
    }
  }

  /**
   * Returns the status name by value
   */
  public static getTypeName(type: string): string {
    const result = this.getTypeList().find(item => item.value === type);

    return result ? result.label : '';
  }
}
