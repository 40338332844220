import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AuthService } from '@emendis/auth';
import { RoleService } from '@shared/services/role.service';
import { Role } from '@shared/models/role.model';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'app-role-selector',
  templateUrl: './role-selector.component.html',
  styleUrls: ['./role-selector.component.scss']
})
export class RoleSelectorComponent implements OnInit {
  public selectedRole: Role;
  public roles: Role[];

  constructor(private authService: AuthService,
              private roleService: RoleService,
              private userService: UserService,
              private location: Location,
              private router: Router) {
  }

  public ngOnInit(): void {
    const user = this.authService.getUser();
    const options = {
      include: [
        'currentRole',
        'roles'
      ]
    };

    this.userService.get(user.id, options).subscribe((response: any) => {
      this.roles = response.roles.data;
      this.selectedRole = response.currentRole.data;
    });
  }

  /**
   * Set the current user role
   */
  public selectRole(): void {
    if (this.selectedRole) {
      this.roleService.setUserRole(this.selectedRole).subscribe(() => {
        this.router.navigate(['/']).then(() => {
          this.router.navigateByUrl('/admin/users/profile');
        });
      });
    }
  }

  /**
   * Hide role selector popup
   */
  public onCancel(): void {
    this.router.navigateByUrl('/admin/users/profile');
  }

}
