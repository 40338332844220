import { Injectable } from '@angular/core';
import { Client } from '@shared/models/client.model';
import { Client as ClientDomain } from '@domain/models/client.model';
import { BehaviorSubject, Observable } from '@node_modules/rxjs';
import { ApiService } from '@emendis/api';
import { map } from '@node_modules/rxjs/internal/operators';
import { Specialty as SpecialtyDomain, Specialty } from '@domain/models/specialty.model';

@Injectable({
  providedIn: 'root'
})
export class ClientService extends ApiService<Client> {

  private currentClient$: BehaviorSubject<ClientDomain> = new BehaviorSubject(null);
  protected endpoint = 'inventory-client';

  /**
   * Get the current client
   *
   */
  public getCurrentClient(): Observable<ClientDomain> {
    return this.currentClient$.asObservable();
  }

  /**
   * Set the current client
   *
   * @param client
   */
  public setCurrentClient(client: ClientDomain): void {
    this.currentClient$.next(client);
  }

  /**
   * Get the specialties of the user and store them in the indexedDB
   *
   * @return Promise<void>
   */
  public async loadClients(): Promise<void> {
    const clients = await this.apiHttp.get(`/${this.endpoint}/sync`).pipe(map((response: { data: Client[] }) => response.data)).toPromise();
    await ClientDomain.query.clear();
    await ClientDomain.query.bulkAdd(clients);
  }
}
