import { Injectable } from '@angular/core';
import { AuthService } from '@emendis/auth';
import { ApiHttpService, ApiService } from '@emendis/api';
import { User as DomainUser } from '@domain/models/user.model';
import { User } from '@shared/models/user.model';
import * as jwtDecode from 'jwt-decode';
import { map } from '@node_modules/rxjs/internal/operators';
import { BehaviorSubject } from '@node_modules/rxjs';

@Injectable()
export class UserService extends ApiService<User> {
  protected endpoint = 'user';
  public userChanged$: BehaviorSubject<void> = new BehaviorSubject(null);

  constructor(public apiHttp: ApiHttpService, private auth: AuthService) {
    super(apiHttp);
  }

  /**
   * Checks if the current user has the given role
   */
  public hasRole(roleCode: string): boolean {
    const jwt = this.getTokenAsObject();

    return jwt.role === roleCode;
  }

  /**
   * Checks if the current user has superuser rights
   */
  public isSuperuser(): boolean {
    return this.hasRole('superuser');
  }

  /**
   * Checks if the current user has administrator rights
   */
  public isAdministrator(): boolean {
    return this.hasRole('superuser') || this.hasRole('administrator');
  }

  /**
   * Decodes and returns the current JWT token string as an object
   */
  private getTokenAsObject(): any {
    // Get current JWT token
    const jwtToken = this.auth.getToken();

    // Decode JWT token
    return jwtDecode(jwtToken);
  }

  /**
   * Get the specialties of the user and store them in the indexedDB
   *
   * @return Promise<void>
   */
  public async loadUsers(): Promise<void> {
    const users = await this.apiHttp.get(`/${this.endpoint}/sync`).pipe(map((response: { data: DomainUser[] }) => response.data)).toPromise();
    await DomainUser.query.clear();
    await DomainUser.query.bulkAdd(users);
  }

  /**
   * Trigger user changed event
   */
  public updateLocalUser(): void {
    this.userChanged$.next();
  }
}
