import { Component, OnInit, ViewChild, OnDestroy, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiHttpService } from '@emendis/api';
import { BaseListComponent } from '@emendis/shared';

import {
  OfflineTableOptions,
  OfflineTableComponent
} from '@shared/controls/table/offline-table.component';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { ProjectService } from '@shared/services/project.service';
import { Project } from '@domain/models/project.model';
import { UserService } from '@shared/services/user.service';
import { TranslateService } from '@node_modules/@ngx-translate/core';
import { LocaleService } from '@shared/services/locale.service';
import { Setting } from '@domain/models/setting.model';
import { BaseDataValue } from '@domain/models/base-data-value.model';
import { UtilService } from '@shared/services/util.service';

@Component({
  selector: 'app-project-overview',
  templateUrl: 'project-overview.component.html'
})
export class ProjectOverviewComponent extends BaseListComponent implements OnInit, OnDestroy {
  @ViewChild('projectOpenTable')
  public projectOpenTable: OfflineTableComponent;

  public projectOpenTableOptions: OfflineTableOptions;
  private subscriptionSynchronisingCompleted: Subscription;

  public constructor(
    public injector: Injector,
    public apiService: ProjectService,
    public userService: UserService,
    public translateService: TranslateService,
    private api: ApiHttpService,
    private synchronisationService: SynchronisationService,
    private router: Router,
    private localeService: LocaleService,
    private utilService: UtilService
  ) {
    super(injector, translateService);

    this.loadTableDataCallback = (data): void => {
      this.transformRows(data);
    };

    this.tableVisible = false;
  }

  public async ngOnInit() {
    this.loadOpenProjectTable();

    const localProjects = await Project.query.toArray();
    const localProjectIds = localProjects.map(project => project.id);

    this.filters = {
      id: {
        value: localProjectIds,
        matchMode: 'NotIn'
      }
    };

    const columns = [];

    if (Setting.getValue('inventory-project.reference_nr.use') === true) {
      columns.push({
        field: 'reference_nr',
        header: this.translateService.instant('inventory-project.reference_nr.label')
      });
    }

    if (Setting.getValue('inventory-project.status_base_data_value_id.use') === true) {
      const projecTypesBaseData = await BaseDataValue.getByBaseDataKey('inventory-project.project-type');
      const projecStatusses = this.utilService.mapToSelectItem(projecTypesBaseData, 'id', 'id', true, 'id', 'base-data');

      columns.push({
        field: 'status_base_data_value_id',
        header: this.translateService.instant('inventory-project.status_base_data_value_id.label'),
        filterField: 'status_base_data_value_id',
        filterType: 'select',
        filterOptions: projecStatusses
      });
    }

    if (Setting.getValue('inventory-project.inventory_client_id.use') === true) {
      columns.push({
        field: 'client.name',
        header: this.translateService.instant('inventory-project.inventory_client_id.label')
      });
    }

    if (Setting.getValue('inventory-project.account_manager_user_id.use') === true) {
      columns.push({
        field: 'accountmanager.name',
        header: this.translateService.instant('inventory-project.account_manager_user_id.label')
      });
    }

    this.setColumns(columns);

    this.tableVisible = true;

    this.subscriptionSynchronisingCompleted = this.synchronisationService.SynchronisingCompleted.subscribe(
      () => {
        this.loadOpenProjectTable();
      }
    );

    // Always synchronise when opening project overview
    this.synchronisationService.synchronise();
    this.synchronisationService.synchroniseStaticData();
  }

  public ngOnDestroy() {
    if (this.subscriptionSynchronisingCompleted) {
      this.subscriptionSynchronisingCompleted.unsubscribe();
    }
  }

  /**
   * Load open projects
   *
   * @return void
   */
  public loadOpenProjectTable(): void {
    const columns = [];

    if (Setting.getValue('inventory-project.reference_nr.use') === true) {
      columns.push({
        title: this.translateService.instant('inventory-project.reference_nr.label'),
        name: 'reference_nr'
      });
    }

    if (Setting.getValue('inventory-project.status_base_data_value_id.use') === true) {
      columns.push({
        title: this.translateService.instant('inventory-project.status_base_data_value_id.label'),
        name: 'status'
      });
    }

    if (Setting.getValue('inventory-project.inventory_client_id.use') === true) {
      columns.push({
        title: this.translateService.instant('inventory-project.inventory_client_id.label'),
        name: 'clientName'
      });
    }

    if (Setting.getValue('inventory-project.inventory_client_id.use') === true) {
      columns.push({
        title: this.translateService.instant('inventory-project.account_manager_user_id.label'),
        name: 'accountmanagerName'
      });
    }

    this.projectOpenTableOptions = new OfflineTableOptions({
      columns: columns,
      withDelete: true,
      url: '/projects',
      tableName: 'projects',
      search: false,
      noResultsMessage: this.translateService.instant('inventory-project.no_projects_found'),
      rowDataTransformer: rows => {
        for (const row of rows) {
          if (row.status_base_data_value_id) {
            row.status = this.translateService.instant(`base-data.${row.status_base_data_value_id}`);
          }
        }

        return rows;
      }
    });
  }

  public async onOpenProjectTableRowClick(data: any): Promise<void> {
    this.router.navigateByUrl(`/admin/project/${data.id}/client`);
  }

  public async onSearchProjectTableRowClick(id: string): Promise<void> {
    // Load project data from server and then navigate to detail view
    await this.synchronisationService.loadSingleProjectData(id);
    this.router.navigateByUrl(`/admin/project/${id}/client`);
  }

  public async addNewProject(): Promise<void> {
    const project = await this.apiService.newProject();
    this.router.navigateByUrl(`/admin/project/${project.id}/client`);
  }

  public async closeOpenProjects(): Promise<void> {
    if (!this.projectOpenTable.selectedRows || this.projectOpenTable.selectedRows.length === 0) {
      this.toastService.warning('Let op', 'Geen projecten geselecteerd');

      return;
    }

    this.confirmationService.confirm({
      message: 'Wilt u de geselecteerde projecten sluiten?',
      header: 'Bevestiging',
      icon: 'fa fa-question-circle',
      accept: async () => {
        this.projectOpenTable.loading = true;
        const closeProjectIds: number[] = this.projectOpenTable.selectedRows.map(row => row.id);
        const result: boolean = await this.synchronisationService.syncToBackend(true);

        if (!result) {
          this.toastService.error('Fout', 'Projecten sluiten mislukt. Controleer uw verbinding.');

          return;
        }

        // Retrieve open projects except selected rows
        for (const row of this.projectOpenTable.rows) {
          if (closeProjectIds.indexOf(row.id) === -1) {
            await this.synchronisationService.loadSingleProjectData(row.id);
          }
        }

        this.loadOpenProjectTable();
        this.loadTableData();
        this.projectOpenTable.loading = false;
      }
    });
  }

  private transformRows(data: any): void {
    // Determine open projects
    const openProjects = this.projectOpenTable.rows;

    const openProjectGuids = openProjects ? openProjects.map(project => project.id) : [];

    // Remove rows which are already opened
    const projects = data.filter(row => {
      if (!openProjectGuids.includes(row.id)) {
        return new Project(row);
      }
    });

    this.tableData = projects.map(project => {
      if (project.status_base_data_value_id) {
        project.status = this.translateService.instant(Project.getStatusName(project.status_base_data_value_id));
      }

      return project;
    });
  }
}
