import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from '@node_modules/rxjs';
import { ModuleTenantService } from '@shared/services/module-tenant.service';
import { QueryOptions, DataService } from '@shared/services/data.service';
import { ModuleTenant } from '@domain/models/module-tenant.model';
import { isUnavailable } from '@shared/services/util.service';

@Injectable({ providedIn: 'root' })
export class ModuleTenantResolver implements Resolve<Observable<any>> {
  constructor(private moduleTenantService: ModuleTenantService, private dataService: DataService) {
  }

  /**
   * Make sure the module tenants are loaded before accessing the route
   *
   * @param route
   * @param state
   *
   * @return Observable<ModuleTenant[]>
   */
  public async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    const moduleTenants = await this.dataService.get('module_tenant', new QueryOptions({ usePaging: false }), '/tenant/module');

    if (isUnavailable(moduleTenants)) {
      return this.moduleTenantService.loadModuleTenants().subscribe();
    }

    return ModuleTenant.loadModuleTenants();
  }
}
