import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from '@node_modules/rxjs';
import { ModuleService } from '@shared/services/module.service';
import { DataService, QueryOptions } from '@shared/services/data.service';
import { Module } from '@domain/models/module.model';
import { isUnavailable } from '@shared/services/util.service';

@Injectable({ providedIn: 'root' })
export class ModuleResolver implements Resolve<Observable<any>> {
  constructor(private moduleService: ModuleService, private dataService: DataService) {
  }

  /**
   * Make sure the modules are loaded before accessing the route
   *
   * @param route
   * @param state
   *
   * @return Observable<Module[]>
   */
  public async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    const modules = await this.dataService.get('modules', new QueryOptions({ usePaging: false }), '/role/module');

    if (isUnavailable(modules)) {
      return this.moduleService.loadModules().subscribe();
    }

    return Module.loadModules();
  }
}
