import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { InventoryClientComponent } from '@features/inventory/client/client.component';
import { InventoryReceiptListComponent } from '@features/inventory/receipt/receipt-list.component';


@Injectable()
export class ReceiptGuard implements CanDeactivate<InventoryReceiptListComponent> {
  private home = '/admin/projects';
  private popup = 'popup';

  canDeactivate(
    component: InventoryReceiptListComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState?: RouterStateSnapshot,
    nextState?: RouterStateSnapshot) {

    return true;
  }
}
