import { Injectable } from '@angular/core';
import { ApiService } from '@emendis/api';
import { Module } from '@shared/models/module.model';
import { Module as ModuleDomain } from '@domain/models/module.model';
import { Observable } from '@node_modules/rxjs';
import { catchError, map, tap } from '@node_modules/rxjs/internal/operators';

@Injectable({
  providedIn: 'root'
})
export class ModuleService extends ApiService<Module> {
  public endpoint = 'role/module';

  /**
   * Get the modules of the user and store them in the indexedDB
   *
   * @return Observable<Module[]>
   */
  public loadModules(): Observable<Module[]> {
    return this.apiHttp.get(`/${this.endpoint}`)
      .pipe(
        map((response: any) => response.data),
        tap(async (modules) => {
          await ModuleDomain.query.clear();
          await ModuleDomain.query.bulkAdd(modules);
          await ModuleDomain.loadModules();
        }),
        catchError(async () => {
          await ModuleDomain.loadModules();
        })
      );
  }

  /**
   * Retrieve all modules
   *
   * @return Observable<Module[]>
   */
  public getModuleList(): Observable<Module[]> {
    return this.apiHttp.get('/module/list').pipe(map((response: { data: Module[] }) => response.data));
  }
}
