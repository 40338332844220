import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { SelectItem } from '@node_modules/primeng/components/common/selectitem';
import { TranslateService } from '@node_modules/@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(private translateService: TranslateService) {
  }

  /**
   * Map array to SelectItem array
   *
   * @param array
   * @param label
   * @param value
   * @param translate
   * @param translateProperty
   * @param translationKeyPrefix
   */
  public mapToSelectItem(array: any[],
                         value: string,
                         label: string,
                         translate: boolean = false,
                         translateProperty: string = 'key',
                         translationKeyPrefix: string = ''): SelectItem[] {
    if (array.length === 0) {
      return [];
    }


    return array.map((item) => {
      let selectItemLabel = item[label];
      if (translate === true) {
        selectItemLabel = this.translateService.instant(`${translationKeyPrefix}.${item[translateProperty]}`);
      }

      return { value: item[value], label: selectItemLabel };
    });
  }

  /**
   * Set date in expected format for the sync
   *
   * @param value string|Date
   * @return string
   */
  public toDateTime(value: string|Date): string {
    return moment(value).format('YYYY-MM-DD HH:mm:ss');
  }

  /**
   * Set date in expected format for (grid) presentation
   *
   * @param value string|Date
   * @return string
   */
  public toDateString(value: string|Date): string {
    // TODO: Nicer would be to get the format from a tenant and/or user setting
    return moment(value).format('DD-MM-YYYY');
  }

  /**
   * Convert an (associative) array to object array
   *
   * @param inputArray any
   * @return any
   */
  public convertArrayToObject(inputArray: any): any {
    let objectArray = {};

    if (inputArray && typeof inputArray === 'object') {
      for (const inputArrayItem of Object.keys(inputArray)) {
        objectArray[inputArrayItem] = this.convertArrayToObject(inputArray[inputArrayItem]);
      }
    } else {
      objectArray = inputArray;
    }

    return objectArray;
  }
}

/**
 * Check if an array is not set or is empty
 *
 * @param array
 */
export function isUnavailable(array: any[]): boolean {
  return !array || typeof array === typeof undefined || array.length === 0;
}
