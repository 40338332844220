import { Injectable } from '@angular/core';
import { ApiService } from '@emendis/api';
import { Supplier } from '@shared/models/supplier.model';
import { Supplier as SupplierDomain } from '@domain/models/supplier.model';
import { map } from '@node_modules/rxjs/internal/operators';

@Injectable({
  providedIn: 'root'
})
export class SupplierService extends ApiService<Supplier> {
  protected endpoint = 'inventory-supplier';

  /**
   * Get the suppliers and store them in the indexedDB
   *
   * @return Promise<void>
   */
  public async loadSuppliers(): Promise<void> {
    const clients = await this.apiHttp.get(`/${this.endpoint}/sync`).pipe(map((response: { data: Supplier[] }) => response.data)).toPromise();
    await SupplierDomain.query.clear();
    await SupplierDomain.query.bulkAdd(clients);
  }
}
