import { DomainModel } from '@domain/domain.model';

export class Module extends DomainModel {
  public static modules: Module[];

  // Configuration
  public entity = 'module';
  public table = 'modules';
  public schema = '++id, name';
  public sync = false;

  // Fields
  public name: string;
  public description: string;
  public id: string;
  public available_offline: boolean;
  public translation_key: string;
  public menu_index: number;

  // Constructor
  constructor(attributes) {
    super(attributes);
  }

  /**
   * Load current modules in static cache
   */
  public static async loadModules(): Promise<void> {
    Module.modules = await Module.query.toArray();
  }

  /**
   * Get module by given name
   *
   * @param name
   *
   * @return Module|undefined
   */
  public static getByName(name: string): Module | undefined {
    if (!Module.modules) {
      return undefined;
    }

    return Module.modules.find(module => module.name === name);
  }

  /**
   * Get the active modules
   *
   * @return list of modules
   */
  public static async getModules(): Promise<Module[]> {
    return await Module.query.toArray();
  }
}
