import { Injectable } from '@angular/core';
import { ApiService } from '@emendis/api';
import { ModuleTenant } from '@shared/models/module-tenant.model';
import { ModuleTenant as ModuleTenantDomain } from '@domain/models/module-tenant.model';
import { Observable } from '@node_modules/rxjs';
import { catchError, map, tap } from '@node_modules/rxjs/internal/operators';

@Injectable({
  providedIn: 'root'
})
export class ModuleTenantService extends ApiService<ModuleTenant> {
  public endpoint = 'tenant/module';

  /**
   * Get the modules of the tenant and store them in the indexedDB
   *
   * @return Observable<ModuleTenant[]>
   */
  public loadModuleTenants(): Observable<ModuleTenant[]> {
    return this.apiHttp.get(`/${this.endpoint}`)
      .pipe(
        map((response: any) => response.data),
        tap(async (moduleTenants) => {
          await ModuleTenantDomain.query.clear();
          await ModuleTenantDomain.query.bulkAdd(moduleTenants);
          await ModuleTenantDomain.loadModuleTenants();
        }),
        catchError(async () => {
          await ModuleTenantDomain.loadModuleTenants();
        })
      );
  }
}
