import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService } from '@emendis/api';
import { Observable } from '@node_modules/rxjs';
import { tap } from '@node_modules/rxjs/internal/operators';
import { AuthService } from '@emendis/auth';
import { Role } from '@shared/models/role.model';
import { SettingService } from '@shared/services/setting.service';
import { PermissionService } from '@shared/services/permission.service';
import { ModuleService } from '@shared/services/module.service';

@Injectable({
  providedIn: 'root'
})
export class RoleService extends ApiService<Role> {
  protected endpoint = 'role';

  constructor(public httpService: ApiHttpService,
              private authService: AuthService,
              private settingService: SettingService,
              private moduleService: ModuleService,
              private permissionService: PermissionService) {
    super(httpService);
  }

  /**
   * Set the current role of the user
   *
   * @param role
   */
  public setUserRole(role: Role): Observable<any> {
    return this.apiHttp.post('/user/role', { role_id: role.id })
      .pipe(tap(() => {
          const user = this.authService.getUser();
          user['currentRole'] = role;
          localStorage.setItem('user', JSON.stringify(user));

          // Refresh the settings/modules/translations
          this.settingService.loadSettings();
          this.permissionService.loadPermissions().subscribe();
          this.moduleService.loadModules().subscribe();
        })
      );
  }
}
