import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from '@node_modules/rxjs';
import { SettingService } from '@shared/services/setting.service';
import { PermissionService } from '@shared/services/permission.service';
import { Permission } from '@shared/models/permission.model';

@Injectable({ providedIn: 'root' })
export class PermissionResolver implements Resolve<Observable<any>> {
  constructor(private permissionService: PermissionService) {
  }

  /**
   * Make sure the settings are loaded before accessing the route
   *
   * @param route
   * @param state
   *
   * @return Promise<any>
   */
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string[]> {
    return this.permissionService.loadPermissions();
  }
}
