import { DomainModel } from '@domain/domain.model';

import * as uuid from 'uuid/v4';

export class OrderPickingItem extends DomainModel {
    // Configuration
    public entity = 'orderpicking-item';
    public table = 'orderpicking_items';
    public schema = 'id, remote_id, project_id, orderpicking_id, inventory_item_id, cargo_container';
    public sync = true;
    public id: string;

    // Fields
    public orderpicking_id?: string;
    public project_id?: string;
    public inventory_item_id?: string;
    public picked?: boolean;
    public amount?: number;
    public max_amount?: number;
    public status_base_data_value_id?: string;

    public storage_container?: string;
    public cargo_container?: string;

    public unloaded_status?: any;
    public unloaded_remarks?: string;
    public unloaded_amount?: number;
    public unloaded_images?: File[];

    public amount_loaded?: number;

    // Constructor
    constructor(attributes) {
        super(attributes);

        if (!attributes.id) {
            this.id = uuid();
        }
    }

    public getData(): any {
        return {
            id: this.id,
            project_id: this.project_id,
            orderpicking_id: this.orderpicking_id,
            inventory_item_id: this.inventory_item_id,
            amount: this.amount,
            max_amount: this.max_amount,
            storage_container: this.storage_container || null,
            cargo_container: this.cargo_container || null,
            status_base_data_value_id: this.status_base_data_value_id || '597c33a8-7d2f-11e9-ba25-0242ac160001',
            unloaded_status: this.unloaded_status,
            unloaded_remarks: this.unloaded_remarks || '',
            unloaded_amount: this.unloaded_amount || 0,
            unloaded_images: this.unloaded_images || [],
            amount_loaded: this.amount_loaded || 0
        };
    }
}
