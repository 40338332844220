import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ErrorHandler, Injector, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppComponent } from './app.component';
import { environment } from '@environments/environment';

import { ConfirmDialogModule, MessageService } from 'primeng/primeng';
import { ConfirmationService } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';

import { ApiModule } from '@emendis/api';
import { AuthModule as EmendisAuthmodule, AuthService } from '@emendis/auth';
// App services
import { AppInjector } from '@root/src/app/app-injector.service';
import { UserService } from '@shared/services/user.service';
import { TenantService } from '@shared/services/tenant.service';
import { DataService } from '@shared/services/data.service';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { SettingService } from '@shared/services/setting.service';
// App modules/components
import { AppRoutingModule } from '@root/src/app/app-routing.module';
import { LayoutsModule } from '@core/layouts/layouts.module';
import { AuthModule } from '@features/auth/auth.module';
import { CoreModule } from '@core/core.module';

import { ProjectGuard } from '@shared/guards/project.guard';
import { UserGuard } from '@shared/guards/user.guard';
import { ClientGuard } from '@shared/guards/client.guard';
import { AddressGuard } from '@shared/guards/address.guard';
import { ContactGuard } from '@shared/guards/contact.guard';
import { ContactListGuard } from '@shared/guards/contact-list.guard';
import { OptionsGuard } from '@shared/guards/options.guard';
import { ReceiptGuard } from '@shared/guards/receipt.guard';
import { InventoryGuard } from '@shared/guards/inventory.guard';
import { QuotationGuard } from '@shared/guards/quotation.guard';
import { BootstrapGuard } from '@shared/guards/bootstrap.guard';
// Sentry init
import * as Raven from 'raven-js';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
  TranslateService
} from '@node_modules/@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@node_modules/@ngx-translate/http-loader';
import { TranslationLoader } from '@core/translate/translation-loader';
import { CustomMissingTranslationHandler } from '@core/translate/custom-missing-translation-handler';
import { NgxPermissionsModule } from '@node_modules/ngx-permissions';
import { AuthGuard } from '@shared/guards/auth.guard';

if (environment.production) {
  Raven.config('https://8f8967473439457489231f2dd674a14e@sentry.emendistest.nl/2').install();
}

export function onAuthError(): void {
  window.location.href = '/#/auth/login';
}

export class RavenErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    if (environment.production) {
      Raven.captureException(err);
    } else {
      throw err;
    }
  }
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    LayoutsModule,
    AuthModule,
    TableModule,
    ConfirmDialogModule,
    ToastModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    EmendisAuthmodule.forRoot({
      baseUrl: environment.baseUrl,
      production: environment.production
    }),
    ApiModule.forRoot({
      baseUrl: environment.baseUrl,
      production: environment.production,
      onAuthError: onAuthError
    }),
    CoreModule,
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler
      },
      loader: {
        provide: TranslateLoader,
        useClass: TranslationLoader
      }
    }),
    NgxPermissionsModule.forRoot()
  ],
  providers: [
    { provide: ErrorHandler, useClass: RavenErrorHandler },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    ProjectGuard,
    ClientGuard,
    UserGuard,
    AddressGuard,
    ContactGuard,
    ContactListGuard,
    OptionsGuard,
    ReceiptGuard,
    AuthGuard,
    InventoryGuard,
    QuotationGuard,
    BootstrapGuard,
    AuthService,
    DataService,
    UserService,
    TenantService,
    SynchronisationService,
    SettingService,
    ConfirmationService,
    MessageService,
    TranslateService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjector.setInjector(injector);
  }
}
