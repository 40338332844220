import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import * as uuid from 'uuid/v4';
import { Observable } from '@node_modules/rxjs';
import { ApiHttpService, ApiService } from '@emendis/api';
import { ToastService } from '@emendis/shared';

import { Project } from '@domain/models/project.model';
import { DataService, QueryOptions } from '@shared/services/data.service';
import { Address } from '@domain/models/address.model';
import { Contact } from '@domain/models/contact.model';
import { Receipt } from '@domain/models/receipt.model';
import { InventoryItem } from '@domain/models/inventory-item.model';
import { DefaultInventory } from '@domain/models/default-inventory.model';
import { Inventory } from '@domain/models/inventory.model';
import { WorkAssignment } from '@domain/models/work-assignment.model';
import { WorkAssignmentItem } from '@domain/models/work-assignment-item.model';
import { Client } from '@domain/models/client.model';
import { WorkAssignmentAddress } from '@domain/models/work-assignment-address.model';
import { ProjectMaterial } from '@domain/models/project-material.model';
import { Module } from '@domain/models/module.model';
import { OrderPicking } from '@domain/models/orderpicking.model';
import { OrderPickingItem } from '@domain/models/orderpicking-item.model';
import { ShippingManifest } from '@domain/models/shippingmanifest.model';
import { ShippingManifestItem } from '@domain/models/shippingmanifest-item.model';
import { TranslateService } from '@node_modules/@ngx-translate/core';
import { Delivery } from '@domain/models/delivery.model';
import { DeliveryItem } from '@domain/models/delivery-item.model';

/**
 * ProjectService
 * This service is designed to provide the project across different classes and routing
 * IMPORTANT: include this service under provider section in a module.
 */
@Injectable({
  providedIn: 'root'
})
export class ProjectService extends ApiService<Project> {
  protected endpoint = 'inventory-project/project';

  private result;

  // Constants
  public maxInventories = 100; // Maximum of inventories to show in list
  public maxInventoryItems = 100; // Maximum of items to show in inventory
  public maxListItems = 50; // MAximum of items to show in default inventory list
  public maxNewDefaultItems = 50; // Maximum of default items to push in new inventory

  // Observables
  public projectLoaded = new Subject<any>();
  public clientChanged = new Subject<any>();
  public defaultInventoryItemsLoaded = new Subject<any>();
  public defaultInventoriesLoaded = new Subject<any>();
  public inventoryDeleted = new Subject<any>();
  public inventoryAdded = new Subject<any>();
  public floorsLoaded = new Subject<any>();
  public addressLoaded = new Subject<any>();
  public addressAdded = new Subject<any>();
  public quotationAdded = new Subject<any>();
  public contactsChanged = new Subject<any>();
  public contactsAdded = new Subject<any>();
  public receiptsLoaded = new Subject<any>();
  public receiptsAdded = new Subject<any>();
  public receiptsChanged = new Subject<any>();
  public shippingManifestsChanged = new Subject<any>();
  public shippingManifestsAdded = new Subject<any>();
  public orderpickingAdded = new Subject<any>();
  public orderpickingChanged = new Subject<any>();
  public deliveriesChanged = new Subject<any>();

  // Models
  public project: Project;
  public address: Address;
  public contact: Contact;
  public receipt: Receipt;
  public shippingManifest: ShippingManifest;
  public orderpicking: OrderPicking;
  public dataQuery = new QueryOptions();

  private currentClient$ = new Subject<Client>();

  /** Static statusses, uuid's are always the same on server */
  private projectStatusses: any[] = [
    { id: '677109b0-5513-11e9-8b60-25cee0667000', value: this.translateService.instant('project.status.new') },
    { id: '677109b0-5513-11e9-8b60-25cee0667001', value: this.translateService.instant('project.status.booked') },
    { id: '677109b0-5513-11e9-8b60-25cee0667002', value: this.translateService.instant('project status pending') },
    { id: '677109b0-5513-11e9-8b60-25cee0667003', value: this.translateService.instant('project.status.lost') },
    { id: '677109b0-5513-11e9-8b60-25cee0667004', value: this.translateService.instant('project.status.inactive') },
    { id: '677109b0-5513-11e9-8b60-25cee0667005', value: this.translateService.instant('project.status.complete') },
    { id: '677109b0-5513-11e9-8b60-25cee0667006', value: this.translateService.instant('project.status.incomplete-send') },
    { id: '677109b0-5513-11e9-8b60-25cee0667007', value: this.translateService.instant('project.status.closed') },
  ];

  constructor(
    public apiHttp: ApiHttpService,
    private dataService: DataService,
    private router: Router,
    private toastService: ToastService,
    public translateService: TranslateService,
    private route: ActivatedRoute
  ) {
    super(apiHttp);
  }

  /**
   * Get project
   * If not exist return null
   */
  getProject() {
    if (!this.project) {
      return new Project({});
    }

    return this.project;
  }

  /**
   * CRUD PROJECT
   */
  async newProject() {
    this.project = new Project({});

    // Create guid for new project
    this.project.id = uuid();
    this.project.is_new = true;

    await this.saveProject();

    return this.project;
  }

  async addDefaultInventories() {
    const defaultInventories = await DefaultInventory.query.toArray();
    for (const defaultInventory of defaultInventories) {
      await this.saveNewInventory(
        new Inventory({
          name: defaultInventory.name,
          default_inventory_id: defaultInventory.id,
          project_id: this.project.id,
          floor: '0',
          building: 'huis'
        })
      );
    }
  }

  /**
   * Load Project by id
   */
  async loadProject(id: string) {
    this.project = await Project.query.get(id);

    if (!this.project) {
      // Project not found, redirect to home screen
      this.router.navigateByUrl('/').then(() => {
        this.toastService.error('link.projects', 'toast.error.message');
      });

      return;
    }

    // TODO: Check if module is available for the logged in user (via tenant)
    if (Module.getByName('inventory-specialty')) {
      await this.project.loadSpecialties();
    }

    if (Module.getByName('inventory-activity')) {
      await this.project.loadActivities();
    }

    if (Module.getByName('inventory-inventory')) {
      await this.project.loadInventories();
    }

    if (Module.getByName('quotation')) {
      await this.project.loadQuotations();
    }

    await this.project.loadAddresses();
    await this.project.loadContacts();
    await this.project.loadReceipts();
    await this.project.loadOrderpickings();

    this.projectLoaded.next(this.project);
  }

  /**
   * Set project status based on input string
   *
   * @return void
   */
  public setProjectStatus(status: string): void {
    const item = this.projectStatusses.find((i) => i.value === status);

    if (!item) {
      return;
    }

    this.project.status_base_data_value_id = item.id;
    this.saveProject();
  }

  /**
   * Update updated_at field to mark project for synchronisation to backend
   * TODO This synchronisation mark should be more efficient and only applied for real updates
   */
  setProjectUpdated() {
    this.project.is_changed = true;
    if (this.project.id) {
      this.dataService.createOrUpdate('projects', this.project);
    }
  }

  /**
   * Save project with clients
   *
   * @returns {Promise<void>}
   */
  public async saveProject() {
    const newProjectId = await this.dataService.createOrUpdate('projects', this.project);

    // Check if private project has default projects loaded
    if (
      this.project.type === 'private' &&
      this.project.reference_nr &&
      this.project.reference_nr.length &&
      (!this.project.inventories || this.project.inventories.length === 0)
    ) {
      await this.addDefaultInventories();
    }

    await this.loadProject(newProjectId);
  }

  async saveClientAndProject() {
    const result = await this.dataService.createOrUpdate('clients', this.project.client);
    this.project.inventory_client_id = this.project.client.id;
    await this.saveProject();
  }

  /**
   * Delete project with id
   * @param id Number
   */
  async deleteProject(id) {
    await this.dataService.delete('projects', id);
  }

  /**
   * Save address
   *
   * @param address Address model
   * @return Promise<any>
   */
  saveAddress(address: Address): Promise<any> {
    this.setProjectUpdated();

    return this.dataService.createOrUpdate('addresses', address).then(() => {
      this.addressAdded.next();
    });
  }

  /**
   * Save address
   *
   * @param address Address model
   */
  saveQuotation(quotation) {
    this.setProjectUpdated();

    // Update date string value
    // TODO Refactor to more sustainable solution
    quotation.updateDate();

    this.dataService.createOrUpdate('quotations', quotation).then(result => {
      this.quotationAdded.next();
    });
  }

  /**
   * Save the orderpickings and their items belonging to projects
   *
   * @param orderpickings: OrderPicking[]
   * @param items: OrderPickingItem[]
   *
   * @return Promise<void>
   */
  public async saveOrderpickings(orderpickings: OrderPicking[], items: OrderPickingItem[]): Promise<void> {
    this.setProjectUpdated();

    orderpickings.forEach(async (orderpicking: OrderPicking) => {
      await this.dataService.createOrUpdate('orderpickings', orderpicking);
    });

    items.forEach(async (item: OrderPickingItem) => {
      await this.dataService.createOrUpdate('orderpicking_items', item);
    });
  }

  /**
   * Save the orderpickings and their items belonging to projects
   *
   * @param orderpicking: OrderPicking
   * @param items: OrderPickingItem[]
   *
   * @return Promise<void>
   */
  public async saveOrderpicking(orderpicking: OrderPicking, items: OrderPickingItem[]): Promise<void> {
    this.setProjectUpdated();

    await this.dataService.createOrUpdate('orderpickings', orderpicking).then(() => {
      this.orderpickingAdded.next();
    });

    // Update orderpicking items
    items.forEach(async (item: OrderPickingItem) => {
      await this.dataService.createOrUpdate('orderpicking_items', item);
    });
  }

  /**
   * Get address with id
   *
   * @param id Number
   */
  async getAddress(id) {
    this.dataQuery = new QueryOptions({
      pageSize: 1,
      columns: [{ name: 'id', filter: id, filterMode: 'equals' }]
    });
    const address = await this.dataService.get('addresses', this.dataQuery, '/address');
    this.address = address[0];
    this.addressLoaded.next(this.address);
  }

  /**
   * Delete address with id
   *
   * @param id: string
   * @returns Promise<void>
   */
  public async deleteAddress(id: string): Promise<void> {
    this.setProjectUpdated();
    await Address.query.delete(id);
  }

  /**
   * Save contact
   *
   * @param contact Contact model
   * @return Promise<any>
   */
  saveContact(contact: Contact): Promise<any> {
    this.setProjectUpdated();

    return Contact.query.add(contact).then(() => {
      this.contactsAdded.next();
    });
  }

  /**
   * Get contact with id
   *
   * @param id: string
   * @returns Promise<void>
   */
  async getContact(id: string): Promise<void> {
    this.contact = await Contact.query.get(id);
    this.contactsChanged.next(this.contact);
  }

  /**
   * Check if project has contacts
   *
   * @returns {Promise<boolean>}
   * @param projectId
   */
  async hasContacts(projectId: string): Promise<boolean> {
    await this.loadProject(projectId);

    return !(!this.project || !this.project.contacts || this.project.contacts.length === 0);
  }

  /**
   * Delete contact with id
   *
   * @param id Number
   */
  deleteContact(id) {
    this.setProjectUpdated();
    this.dataService.delete('contacts', id);
  }

  /**
   * Save shipping manifest
   *
   * @param shippingManifest ShippingManifest model
   */
  public async saveShippingManifest(shippingManifest): Promise<void> {
    this.setProjectUpdated();
    this.dataService.createOrUpdate('shippingmanifests', shippingManifest).then(result => {
      this.shippingManifestsAdded.next();
    });
  }

  /**
   * Update shipping manifest
   *
   * @param shippingManifest: ShippingManifest
   * @returns Promise<void>
   */
  public async updateShippingManifest(shippingManifest: ShippingManifest): Promise<void> {
    this.setProjectUpdated();
    this.dataService.update('shippingmanifests', shippingManifest.id, shippingManifest).then((manifest) => {
      this.shippingManifestsChanged.next(manifest);
    });
  }

  /**
   * Create or update Shipping Manifest Items
   * 
   * @param items: ShippingManifestItems[]
   * @returns Promise<void>
   */
  public async createOrUpdateShippingManifestItems(items: ShippingManifestItem[]): Promise<void> {
    this.setProjectUpdated();
    for (const item of items) {
      await this.dataService.createOrUpdate('shippingmanifest_items', item);
    }
  }

  /**
   * Delete Shipping Manifest Items
   * 
   * @param shippingManifestId: string
   * @returns Promise<void>
   */
  public async deleteShippingManifestItems(shippingManifestId: string): Promise<void> {
    this.setProjectUpdated();
    await ShippingManifest.query.where('shipping_manifest_id').equals(shippingManifestId).delete().then(() => {
      // Also delete any Deliveries associated with this manifest
      Delivery.query.where('deliverable_id').equals(shippingManifestId).delete();
    });
  }

  /**
   * Delete the shippingmanifest
   *
   * @param id: string
   * @returns void
   */
  public async deleteShippingManifest(id: string): Promise<void> {
    this.setProjectUpdated();
    ShippingManifest.query.delete(id).then(async () => {
      await this.deleteShippingManifestItems(id);
      this.shippingManifestsChanged.next();
    });
  }

  /**
   * Get shipping manifest with id
   *
   * @param id: string
   * @returns Promise<void>
   */
  public async getShippingManifest(id: string): Promise<void> {
    const shippingManifest = await ShippingManifest.query.get(id);
    await shippingManifest.init();
    this.shippingManifest = shippingManifest;
    this.shippingManifestsChanged.next(this.shippingManifest);
  }

  /**
   * Creates or Updates the Delivery Model
   * 
   * @param delivery: Delivery
   * @returns Promise<void>
   */
  public async createOrUpdateDelivery(delivery: Delivery): Promise<void> {
    this.setProjectUpdated();
    this.dataService.createOrUpdate('deliveries', delivery).then(() => {
      this.deliveriesChanged.next();
    });
  }

  /**
   * Deletes the Delivery Model and items associated
   * 
   * @param delivery: Delivery
   * @returns Promise<void>
   */
  public async deleteDelivery(delivery: Delivery): Promise<void> {
    this.setProjectUpdated();
    Delivery.query.delete(delivery.id).then(() => {
      this.deleteDeliveryItems(delivery.id);
    });
  }

  /**
   * Deletes all delivery Items associated to the delivery
   * 
   * @param deliveryId: string
   * @returns Promise<void>
   */
  public async deleteDeliveryItems(deliveryId: string): Promise<void> {
    const items: DeliveryItem[] = await DeliveryItem.query.where('inventory_delivery_id').equals(deliveryId).toArray();

    for (const item of items) {
      await DeliveryItem.query.delete(item.id);
    }
  }

  /**
   * Creates or Updates the Delivery Items
   * 
   * @param deliveryItems: DeliveryItem[]
   * @returns void
   */
  public async createOrUpdateDeliveryItems(deliveryItems: DeliveryItem[]): Promise<void> {
    this.setProjectUpdated();

    for (const item of deliveryItems) {
      await this.dataService.createOrUpdate('delivery_items', item);
    }
  }

  /**
   * Save unloading items
   *
   * @param item: OrderPickingItem
   * @return Promise<void>
   */
  public async saveUnloadingItem(item: OrderPickingItem): Promise<void> {
    await this.dataService.update('orderpicking_items', item.id, item);
    this.setProjectUpdated();
  }

  /**
   * Get orderpicking with id
   *
   * @param id: string
   * @returns void
   */
  public async getOrderpicking(id: string): Promise<void> {
    this.orderpicking = await OrderPicking.query.get(id);
    await this.orderpicking.init();
    this.orderpickingChanged.next(this.orderpicking);
  }

  /**
   * Delete the orderpicking
   *
   * @param id: string
   * @returns void
   */
  public async deleteOrderpicking(id: string): Promise<void> {
    this.setProjectUpdated();
    OrderPicking.query.delete(id).then(() => {
      this.shippingManifestsChanged.next();
    });

    const items = await OrderPickingItem.query.where('orderpicking_id').equals(id).toArray() || [];
    items.forEach(async (item) => {
      await OrderPickingItem.query.delete(item.id);
    });
  }

  /**
   * Save receipt
   *
   * @param receipt Receipt model
   * @return Promise<any>
   */
  saveReceipt(receipt: Receipt): Promise<any> {
    this.setProjectUpdated();

    return this.dataService.createOrUpdate('receipts', receipt).then(() => {
      this.receiptsChanged.next();
    });
  }

  /**
   * Get receipt with id
   *
   * @param id Number
   */
  async getReceipt(id) {
    this.dataQuery = new QueryOptions({
      pageSize: 1,
      columns: [{ name: 'id', filter: id, filterMode: 'equals' }]
    });
    const receipt = await this.dataService.get('receipts', this.dataQuery, '/receipt');
    this.receipt = receipt[0];
    this.receiptsLoaded.next(this.receipt);
  }

  /**
   * Check if project has receipts
   *
   * @returns {Promise<boolean>}
   * @param projectId
   */
  async hasReceipts(projectId: string) {
    if (!this.project) {
      await this.loadProject(projectId);
    }

    if (!this.project || !this.project.client.id) {
      return false;
    }

    const criteria = { client_id: this.project.client.id };
    let receipt = await this.dataService
      .getWhere('receipts', criteria, `/receipt?client_id=${this.project.client.id}`)
      .then(item => (receipt = item));

    return receipt !== undefined;
  }

  /**
   * Delete receipt with id
   *
   * @param id Number
   */
  deleteReceipt(id) {
    this.setProjectUpdated();
    this.dataService.delete('receipts', id);
  }

  /**
   * Get work assignment by id
   */
  async getWorkAssignment(id: number | string) {
    this.dataQuery = new QueryOptions({
      pageSize: 1,
      columns: [{ name: 'id', filter: id, filterMode: 'equals' }]
    });
    const workAssignment = await this.dataService.get('work_assignments', this.dataQuery, '/work_assignment');
    if (workAssignment && workAssignment[0]) {
      workAssignment[0].init();
      return workAssignment[0];
    }
  }

  async getAllWorkAssignments(id: number | string) {
    this.dataQuery = new QueryOptions({
      columns: [{ name: 'project_id', filter: id, filterMode: 'equals' }]
    });
    const workAssignments = await this.dataService.get('work_assignments', this.dataQuery, '/work_assignment');
    if (workAssignments && workAssignments[0]) {
      for (const assignment of workAssignments) {
        assignment.init();
      }
      return workAssignments;
    }
  }

  /**
   * Save work assignment
   */
  async saveWorkAssignment(workAssignment: WorkAssignment) {
    this.setProjectUpdated();
    // Update date string value
    // TODO Refactor to more sustainable solution
    workAssignment.updateDate();
    const result = await this.dataService.createOrUpdate('work_assignments', workAssignment);

    // Save items
    for (const item of workAssignment.items) {
      await this.dataService.createOrUpdate('work_assignment_items', item);
    }

    // Save items
    for (const item of workAssignment.address_work_assignments) {
      await this.dataService.createOrUpdate('address_work_assignments', item);
    }
  }

  /**
   * Delete work assignment
   * @param workAssignment
   */
  async deleteWorkAssignment(workAssignment: WorkAssignment) {
    // First delete items
    await workAssignment.init();
    for (const item of workAssignment.items) {
      await this.dataService.delete('work_assignment_items', item.id);
    }

    this.setProjectUpdated();
    await this.dataService.delete('work_assignments', workAssignment.id);
  }

  async deleteProjectActivity(activityId: string) {
    this.setProjectUpdated();
    await this.dataService.delete('project_activities', activityId);
  }

  /**
   * Delete work assignment item
   * @param workAssignmentAddress: WorkAssignmentAddress
   */
  async deleteWorkAssignmentItem(workAssignmentItem: WorkAssignmentItem) {
    this.setProjectUpdated();
    await this.dataService.delete('work_assignment_items', workAssignmentItem.id);
  }

  /**
   * Delete work assignment address
   * @param workAssignmentAddress: WorkAssignmentAddress
   */
  async deleteWorkAssignmentAddress(workAssignmentAddress: WorkAssignmentAddress) {
    this.setProjectUpdated();
    await this.dataService.delete('address_work_assignments', workAssignmentAddress.id);
  }

  /**
   * Save specialties
   *
   * @param specialities Array of specialties
   * @param projectId the id of the project
   * @return Promise<any>
   */
  public async saveSpecialties(specialities, projectId): Promise<any> {

    for (const specialty of specialities) {
      // Update date string value

      if (specialty.applicable === true) {
        specialty.project_id = projectId;
        specialty.specialty_id = specialty.id;
        await this.dataService.createOrUpdate('project_specialties', specialty);
      }

      // only delete a specialty when the applicable is manual changed
      if (specialty.applicable === false) {
        await this.dataService.delete('project_specialties', specialty.id);
      }
    }
  }

  /**
   * Save activites
   *
   * @param activities Array of activities
   */
  async saveActivities(activities) {
    for (const activity of activities) {
      // Update date string value
      // TODO Refactor to more sustainable solution
      activity.updateDate();
      await this.dataService.createOrUpdate('project_activities', activity);
    }
  }

  /**
   * Get client with id
   * @param id Client id
   */
  async getClient(id) {
    this.dataQuery = new QueryOptions({
      pageSize: 1,
      columns: [{ name: 'id', filter: id, filterMode: 'equals' }]
    });
    const client = await this.dataService.get('clients', this.dataQuery, '/client');
    this.project.client = client[0];
    this.project.inventory_client_id = this.project.client.id;
    this.clientChanged.next(this.project.client);
  }

  /**
   * Create or update inventory_item
   * @param inventoryItem InventoryItem model
   */
  async createOrUpdateInventoryItem(inventoryItem) {
    this.setProjectUpdated();
    await this.dataService.createOrUpdate('inventory_items', inventoryItem);
  }

  /**
   * Write default_inventory_items to inventory_items for specific inventory
   *
   * @param inventoryId Number
   */
  async writeDefaultsToInventoryItems(inventoryId) {
    // TODO: add remote route to get default inventory items and default items
    const inventory = await this.dataService.getById('inventories', inventoryId);
    if (!inventory) {
      return;
    }

    const defaultInventoryItems = await this.dataService.getBy(
      'default_inventory_items',
      'default_inventory_id',
      inventory.default_inventory_id
    );
    for (const item of defaultInventoryItems) {
      const defaultItem = await this.dataService.getById('default_items', item.default_item_id);
      const inventoryItem = new InventoryItem({
        inventory_id: inventoryId,
        name: defaultItem.name,
        volume: defaultItem.volume,
        meterbox: defaultItem.meterbox
      });
      this.createOrUpdateInventoryItem(inventoryItem);
    }
    await this.project.loadInventories();
  }

  /**
   * Delete inventory with id
   * @param id Number
   */
  async deleteInventoryItem(id) {
    this.setProjectUpdated();
    await this.dataService.delete('inventory_items', id);
    await this.project.loadInventories();
  }


  /**
   * Delete inventory with id
   * @param inventoryItem
   */
  public changeAmountInventoryItem(inventoryItem) {
    this.setProjectUpdated();
    this.createOrUpdateInventoryItem(inventoryItem);
  }

  /**
   * Delete inventory with id and assocciated inventory_items
   *
   * @param id Number
   */
  async deleteInventory(id) {
    this.setProjectUpdated();
    await this.dataService.delete('inventories', id);

    this.dataQuery = new QueryOptions({
      pageSize: this.maxInventoryItems,
      columns: [{ name: 'inventory_id', filter: id, filterMode: 'equals' }]
    });
    const inventories = await this.dataService.get('inventory_items', this.dataQuery, '/inventories');
    this.result = inventories;
    this.result.forEach(async inventory => {
      await this.dataService.delete('inventory_items', inventory.id);
    });
    await this.project.loadInventories();
    this.inventoryDeleted.next();
  }

  /**
   * Save new inventory
   * In addition write default_inventory_items to inventory_items
   *
   * @param inventory Inventory model
   */
  async saveNewInventory(inventory) {
    this.setProjectUpdated();
    const newInventoryId = await this.dataService.createOrUpdate('inventories', inventory);

    if (inventory.default_inventory_id != null) {
      await this.writeDefaultsToInventoryItems(newInventoryId);
    } else {
      await this.project.loadInventories();
    }

    this.inventoryAdded.next(newInventoryId);
  }

  /**
   * Update inventory
   *
   * @param inventory Inventory model
   */
  updateInventory(inventory) {
    this.setProjectUpdated();
    this.dataService.createOrUpdate('inventories', inventory);
  }

  /**
   * Get default inventories
   */
  async getDefaultInventories() {
    const rooms = [];
    this.dataQuery = new QueryOptions({
      pageSize: this.maxListItems,
      columns: [{ name: 'type', filter: this.project.client.type, filterMode: 'equals' }]
    });
    const list = await this.dataService.get('default_inventories', this.dataQuery, '/default-inventory/list');
    this.result = list;
    this.result.forEach(item => {
      rooms.push({ label: item.name, value: item.id });
    }, this);
    this.defaultInventoriesLoaded.next(rooms);
  }

  /**
   * Update project material
   */
  updateMaterial(projectMaterial: ProjectMaterial) {
    this.setProjectUpdated();
    this.dataService.createOrUpdate('project_materials', projectMaterial);
  }

  /**
   * Calculates total volume of all inventories for the current project
   */
  public calculateVolume(): number {
    let volumeTotal = 0;
    if (this.project.inventories && this.project.inventories.length > 0) {
      this.project.inventories.forEach(inventory => {
        if (inventory) {
          let volume = 0;
          inventory.items.forEach(item => {
            volume += item.amount * item.volume || 0;
          });
          volumeTotal += volume;
          inventory.volume = Math.round(volume * 100) / 100;
        }
      });
    }

    return Math.round(volumeTotal * 100) / 100;
  }

  public calculatePackingTotal(): number {
    if (!this.project.inventories) {
      return 0;
    }

    let packingTotal = 0;
    for (const inventory of this.project.inventories) {
      packingTotal += +inventory.packing_amount;
    }

    return packingTotal;
  }

  public calculateAssemblyTotal(): number {
    if (!this.project.inventories) {
      return 0;
    }

    let assemblyTotal = 0;
    for (const inventory of this.project.inventories) {
      for (const inventoryItem of inventory.items) {
        assemblyTotal += (inventoryItem.assemble ? 0.5 : 0) + (inventoryItem.disassemble ? 0.5 : 0);
      }

      assemblyTotal += +inventory.assembly_amount;
    }

    return assemblyTotal;
  }

  public calculateMeterboxTotal(): number {
    if (!this.project.inventories) {
      return 0;
    }

    let meterboxTotal = 0;

    for (const inventory of this.project.inventories) {
      for (const inventoryItem of inventory.items) {
        // Don't add any boxes when it's a "kast"
        if (inventoryItem.name && !inventoryItem.name.toLowerCase().includes('kast')) {
          meterboxTotal += inventoryItem.amount * inventoryItem.meterbox || 0;
        }
      }
    }

    return meterboxTotal;
  }

  public setCurrentClient(client: Client): void {
    this.currentClient$.next(client);
  }

  public getCurrentClient(): Observable<Client> {
    return this.currentClient$.asObservable();
  }
}
