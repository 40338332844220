import { DomainModel } from '@domain/domain.model';
import { File } from '@shared/models/file.model';

import * as uuid from 'uuid/v4';

/**
 * Receipt model class
 */
export class Receipt extends DomainModel {
  // Configuration
  public entity = 'receipt';
  public table = 'receipts';
  public schema = 'id, remote_id, project_id';
  public sync = true;
  public id: string;

  // Fields
  public project_id: string;
  public inventory_supplier_id?: string;
  public unloaded_at: string | Date;
  public remark?: string;
  public images?: File[];
  public signature_supplier_image?: string;
  public signature_unloader_image?: string;
  public storage_number?: string;

  /**
   * Constructor
   *
   * @param attributes
   */
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }

  /**
   * Retrieve the receipt
   *
   * @return any
   */
  public getData(): any {
    return {
      id: this.id,
      project_id: this.project_id,
      inventory_supplier_id: this.inventory_supplier_id || '',
      unloaded_at: this.unloaded_at || null,
      remark: this.remark || '',
      images: this.objectsToArray(this.images) || [],
      signature_supplier_image: this.signature_supplier_image || null,
      signature_unloader_image: this.signature_unloader_image || null,
      storage_number: this.storage_number || ''
    };
  }
}
