import { DomainModel } from '@domain/domain.model';

import * as uuid from 'uuid/v4';
import { Address } from './address.model';
import { OrderPicking } from './orderpicking.model';
import { ShippingManifestItem } from './shippingmanifest-item.model';

export class ShippingManifest extends DomainModel {
  // Configuration
  public entity = 'shippingmanifest';
  public table = 'shippingmanifests';
  public schema = 'id, remote_id, project_id, client_id';
  public sync = true;
  public id: string;

  // Fields
  public project_id?: string;
  public address_id?: string;
  public orderpicking_ids?: string[];

  public items?: ShippingManifestItem[];
  public address?: Address;
  public orderpickings?: OrderPicking[];

  // Unloaded
  public signature_client_unloaded_image?: string;
  public signature_unloader_unloaded_image?: string;
  public unloaded_at?: string | Date;
  public unloaded_images?: File[];
  public unloaded_remarks?: string;

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }

  public async init(): Promise<any> {
    await this.loadManifestItems();
    await this.loadAddress();
    await this.loadOrderPickings();
  }

  /**
   * Retrieves all the items associated to this manifest
   * 
   * @returns Promise<void>
   */
  private async loadManifestItems(): Promise<void> {
    this.items = [];
    const items = await ShippingManifestItem.query.where('shipping_manifest_id').equals(this.id).toArray();

    for (const item of items) {
      await item.init();
      this.items.push(item);
    }
  }

  /**
   * Retrieves the Address associated with the manifest
   *
   * @returns Promise<void>
   */
  private async loadAddress(): Promise<void> {
    if (!this.address_id) {
      return;
    }

    this.address = await Address.query.get(this.address_id);
  }

  /**
   * Retrieves the orderpickings associated with the manifest
   *
   * @returns Promise<void>
   */
  private async loadOrderPickings(): Promise<void> {
    if (!this.orderpicking_ids || this.orderpicking_ids.length < 1) {
      return;
    }

    this.orderpickings = [];
    this.orderpicking_ids.forEach(async (id) => {
      const orderpicking = await OrderPicking.query.get(id);
      this.orderpickings.push(orderpicking);
    });
  }

  public getData(): any {
    return {
      id: this.id,
      project_id: this.project_id,
      inventory_project_id: this.project_id,
      address_id: this.address_id,
      address: this.address,
      orderpicking_ids: this.orderpicking_ids,
      unloaded_at: this.unloaded_at || null,
      unloaded_images: this.unloaded_images,
      unloaded_remarks: this.unloaded_remarks,
      signature_client_unloaded_image: this.signature_client_unloaded_image,
      signature_unloader_unloaded_image: this.signature_unloader_unloaded_image,
      items: this.items
    };
  }
}
