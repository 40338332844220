import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from '@node_modules/rxjs';
import { map, tap } from '@node_modules/rxjs/internal/operators';
import { ApiHttpService, ApiService } from '@emendis/api';
import { File } from '@shared/models/file.model';

@Injectable({
  providedIn: 'root'
})
export class FileService extends ApiService<File> {
  protected endpoint = 'file';

  constructor(private api: ApiHttpService,
              private domSanitizer: DomSanitizer) {
    super(api);
  }

  /**
   * Get the file
   *
   * @param id string
   * @return Observable<File>
   */
  public getFile(id: string): Observable<File> {
    return this.apiHttp.get(`/${this.endpoint}/${id}`)
    .pipe(
      map((response: any) => response.data),
      tap(async (file) => {
        // TODO: Tap is normally used for side-effects, but it works for the current implementation
        if (file.file) {
          file.file = this.domSanitizer.bypassSecurityTrustUrl(`data:${file.mime_type};base64,${file.file}`);
        }
      })
    );
  }
}
