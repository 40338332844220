import { BUILD_TIMESTAMP } from '@environments/timestamp';
const packageJson = require('@root/package.json');

export const environment = {
  production: false,
  hmr: false,
  name: 'Acceptatie',
  baseUrl: 'https://api.inventorycomplete.acceptatie.emendis.nl/api',
  version: packageJson.version + '.' + BUILD_TIMESTAMP,
  features: {
    arent_materials: true
  }
};
