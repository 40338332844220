import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from '@node_modules/rxjs';
import { SettingService } from '@shared/services/setting.service';
import { DataService, QueryOptions } from '@shared/services/data.service';
import { Setting } from '@domain/models/setting.model';
import { isUnavailable } from '@shared/services/util.service';

@Injectable({ providedIn: 'root' })
export class SettingResolver implements Resolve<Observable<any>> {
  constructor(private settingService: SettingService, private dataService: DataService) {
  }

  /**
   * Make sure the settings are loaded before accessing the route
   *
   * @param route
   * @param state
   *
   * @return Promise<any>
   */
  public async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    const settings = await this.dataService.get('settings', new QueryOptions({ usePaging: false }), '/role/setting');

    if (isUnavailable(settings) === true) {
      return this.settingService.loadSettings();
    }

    return Setting.loadSettings();
  }
}
