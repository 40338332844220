import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService } from '@emendis/api';
import { Observable } from '@node_modules/rxjs';
import { Permission } from '@shared/models/permission.model';
import { Permission as PermissionDomain } from '@domain/models/permission.model';
import { catchError, map } from '@node_modules/rxjs/internal/operators';
import { NgxPermissionsService } from '@node_modules/ngx-permissions';

@Injectable({
  providedIn: 'root'
})
export class PermissionService extends ApiService<Permission> {
  protected endpoint = 'permission';

  constructor(private api: ApiHttpService, private ngxPermissionService: NgxPermissionsService) {
    super(api);
  }

  /**
   * Load the permissions of the current user and store them in indexedDB
   *
   * @return Observable<string[]>
   */
  public loadPermissions(): Observable<string[]> {
    return new Observable((resolver) => {
      this.apiHttp.get(`/role/${this.endpoint}`)
        .pipe(
          map((response: any) => response.data),
          catchError(async () => {
            await PermissionDomain.loadPermissions();
            const permissions = PermissionDomain.permissions.map((permission) => permission.name);
            this.ngxPermissionService.flushPermissions();
            this.ngxPermissionService.loadPermissions(permissions);

            resolver.complete();
          })
        ).subscribe(async (permissions) => {
        await PermissionDomain.query.clear();
        const permissionData = permissions.map((permission) => {
          return { name: permission };
        });

        await PermissionDomain.query.bulkAdd(permissionData);
        await PermissionDomain.loadPermissions();

        this.ngxPermissionService.loadPermissions(permissions);

        resolver.complete();
      });
    });
  }

  /**
   * Retrieve all permissions of the current tenant
   *
   * @return Observable<Permission[]>
   */
  public listPermissions(): Observable<Permission[]> {
    return this.apiHttp.get('/tenant/permission').pipe(map((response: { data: Permission[] }) => response.data));
  }
}
