import { Injectable } from '@angular/core';
import { ApiService } from '@emendis/api';
import { map } from '@node_modules/rxjs/internal/operators';
import { BaseData } from '@shared/models/base-data';
import { BaseDataValue } from '@domain/models/base-data-value.model';

@Injectable({
  providedIn: 'root'
})
export class BaseDataService extends ApiService<BaseData[]> {
  /**
   * Retrieve baseData and store in indexeddb
   */
  public async loadBaseData(): Promise<void> {
    const baseDataValues = await this.apiHttp.get('/role/base-data').pipe(map((response: { data: BaseData[] }) => response.data))
      .toPromise();
    await BaseDataValue.query.clear();
    await BaseDataValue.query.bulkPut(baseDataValues);
  }

  /**
   * Load current modules in static cache
   */
  public async getBaseData(): Promise<void> {
    const test = await BaseDataValue.query.toArray();
  }
}
