import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserService } from '@shared/services/user.service';
import { ToastService } from '@emendis/shared';

@Injectable()
export class QuotationGuard implements CanActivate {
  constructor(
    private user: UserService,
    private toastService: ToastService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.user.isAdministrator() || this.user.hasRole('user')) {
      return true;
    }

    this.toastService.error('toast.error.title', 'toast.error.permission-denied');

    return false;
  }
}
