import { DomainModel } from '@domain/domain.model';

import * as uuid from 'uuid/v4';

/**
 * ReceiptItem model class
 */
export class ReceiptItem extends DomainModel {
  // Configuration
  public entity = 'receipt-item';
  public table = 'receipt_items';
  public schema = 'id, remote_id, receipt_id';
  public sync = true;
  public id: string;

  // Fields
  public receipt_id: string;
  public amount?: number;
  public max_amount?: number;
  public volume?: number;
  public assemble?: boolean;
  public disassemble?: boolean;
  public storage_number?: string;
  public storage_container?: string;

  /**
   * Constructor
   *
   * @param attributes
   */
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }

  /**
   * Retrieve the receipt
   *
   * @return any
   */
  public getData(): any {
    return {
      id: this.id,
      receipt_id: this.receipt_id,
      amount: this.amount || 0,
      max_amount: this.max_amount || 0,
      volume: this.volume || 0,
      assemble: this.assemble || false,
      disassemble: this.disassemble || false,
      storage_number: this.storage_number || '',
      storage_container: this.storage_container || ''
    };
  }
}
