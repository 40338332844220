import { DomainModel } from '@domain/domain.model';
import Locale from '@emendis/auth/lib/locale.interface';

import * as uuid from 'uuid/v4';

export class LocaleTenant extends DomainModel implements Locale {
  public static locales: Locale[];

  // Configuration
  public entity = 'locale';
  public table = 'locales';
  public schema = 'id, remote_id, name';
  public sync = true;

  // Fields
  public id: string;
  public name: string;
  public code: string;


  /**
   * Constructor
   * 
   * @param attributes: any
   */
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
        this.id = uuid();
    }
  }

  /**
   * Load current locales in static cache
   * 
   * @returns Promise<void>
   */
  public static async loadLocales(): Promise<void> {
    LocaleTenant.locales = await LocaleTenant.query.toArray();
  }
}
