import { DomainModel } from '@domain/domain.model';

import * as uuid from 'uuid/v4';

export class DeliveryItem extends DomainModel {
  // Configuration
  public entity = 'delivery-item';
  public table = 'delivery_items';
  public schema = 'id, inventory_delivery_id, itemable_id';
  public sync = true;
  public id: string;

  // Fields
  public inventory_delivery_id?: string;
  public amount?: number;
  public itemable_id?: string; // this is inventory_item_id

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }

  public getData(): any {
    return {
      id: this.id,
      inventory_delivery_id: this.inventory_delivery_id,
      amount: this.amount,
      itemable_id: this.itemable_id
    };
  }
}
