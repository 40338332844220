import { Injectable } from '@angular/core';

import { from as observableFrom, Subject } from 'rxjs';
import { Observable } from '@node_modules/rxjs';

import { ApiHttpService } from '@emendis/api';
import { AuthService } from '@emendis/auth';
import { ToastService } from '@emendis/shared';

import { Store } from '@domain/store';
import { Project } from '@domain/models/project.model';
import { Client } from '@domain/models/client.model';
import { Address } from '@domain/models/address.model';
import { Contact } from '@domain/models/contact.model';
import { ProjectSpecialty } from '@domain/models/project-specialty.model';
import { Inventory } from '@domain/models/inventory.model';
import { Module } from '@domain/models/module.model';
import { Receipt } from '@domain/models/receipt.model';
import { InventoryItem } from '@domain/models/inventory-item.model';
import { OrderPicking } from '@domain/models/orderpicking.model';

import { UtilService } from '@shared/services/util.service';
import { SettingService } from './setting.service';
import { DataService } from '@shared/services/data.service';
import { BaseDataService } from '@shared/services/base-data.service';
import { TranslationService } from '@shared/services/translation.service';
import { PermissionService } from '@shared/services/permission.service';
import { ModuleService } from '@shared/services/module.service';
import { SpecialtyService } from '@shared/services/specialty.service';
import { UserService } from '@shared/services/user.service';
import { LocaleService } from './locale.service';
import { ClientService } from '@shared/services/client.service';
import { SupplierService } from '@shared/services/supplier.service';
import { Supplier } from '@domain/models/supplier.model';
import { ShippingManifest } from '@domain/models/shippingmanifest.model';

@Injectable()
export class SynchronisationService {
  public SynchronisingCompleted = new Subject<any>();
  public shouldSync: boolean;
  private store = Store.getStore();
  private state = { added: false, finished: false };

  constructor(
    private api: ApiHttpService,
    private auth: AuthService,
    private dataService: DataService,
    private toastService: ToastService,
    private utilService: UtilService,
    private settingService: SettingService,
    private baseDataService: BaseDataService,
    private translationService: TranslationService,
    private permissionService: PermissionService,
    private moduleService: ModuleService,
    private specialtyService: SpecialtyService,
    private userService: UserService,
    private localeService: LocaleService,
    private clientService: ClientService,
    private supplierService: SupplierService,
  ) {
    // Register to internet connection online event
    window.addEventListener(
      'online',
      e => {
        if (this.shouldSync) {
          this.synchronise();
          this.synchroniseStaticData();
          this.shouldSync = false;
        }
      },
      false
    );
  }

  /**
   * Sync static data attachable in projects
   *
   * Clients, materials, activities, base data
   *
   * @return Promise<any>
   */
  public async synchroniseStaticData(): Promise<any> {
    // Do not synchronize when not authenticated
    if (!this.auth.isAuthenticated()) {
      return;
    }

    // Check internet status, if not online, then sync later
    if (!navigator.onLine) {
      this.shouldSync = true;
      return;
    }

    await this.baseDataService.loadBaseData();

    await this.translationService.getTranslations();
    await this.userService.loadUsers();

    // Reload settings
    await this.settingService.loadSettings();
    await this.baseDataService.loadBaseData();
    await this.moduleService.loadModules();
    await this.localeService.loadLocales();
    await this.permissionService.loadPermissions();
    await this.translationService.loadTranslations();
    await this.specialtyService.loadSpecialties();
    await this.clientService.loadClients();
    await this.supplierService.loadSuppliers();
  }

  /**
   * Sync project data
   *
   * @return Promise<any>
   */
  public async synchronise(): Promise<any> {
    // Do not synchronize when not authenticated
    if (!this.auth.isAuthenticated()) {
      return;
    }

    // Check internet status, if not online, then sync later
    if (!navigator.onLine) {
      this.shouldSync = true;
      return;
    }

    this.showSyncStartToast('synchronisation.data-synced');

    // First sync new and changed data to backend
    await this.syncToBackend();

    await this.baseDataService.loadBaseData();

    this.showSyncResultToast();

    this.SynchronisingCompleted.next(this.state);
  }

  /**
   * Sync projects to the backend
   *
   * @param clearOnSuccess boolean
   * @return Promise<boolean>
   */
  public async syncToBackend(clearOnSuccess: boolean = false): Promise<boolean> {
    const projects = await Project.query.toArray();

    for (const project of projects) {
      // Check if id is set
      if (!project.id) {
        continue;
      }

      const syncModel = await this.buildSyncModel();
      const data = await this.getSyncJson(project);
      const newData = JSON.parse(JSON.stringify(data));
      const originalData = project._original ? JSON.parse(JSON.stringify(project._original)) : {};

      // Compare new data and original
      let diff = this.getDiff(newData, originalData);
      diff = { ...syncModel, ...diff };

      // Apply changes to server
      const result = await this.api.post<any>(
        `/inventory-project/project/synchronize/${project.id}`,
        { data: this.utilService.convertArrayToObject(diff) }
        ).toPromise();

      if (!result || !result.data || Object.keys(result.data).length === 0) {
        // TODO: Do something with this error situation
        console.log('Project sync error...');
      } else {
        // Update local project with latest data from server
        await this.saveSyncResult(project, result, project.id);
      }
    }

    if (clearOnSuccess) {
      // Clear project data
      const projectTables = await this.getProjectTables();

      Store.getStore().tables.forEach(table => {
        if (!projectTables.includes(table.name)) {
          return;
        }

        table.clear();
      });
    } else {
      // Update status
      const updateProjects = await Project.query.toArray();

      for (const project of updateProjects) {
        project.is_changed = false;
        project.is_new = false;
        await this.dataService.createOrUpdate('projects', project);
      }
    }

    return true;
  }

  /**
   * Retrieves a single projects from backend and updates the client store
   *
   * @param projectId string
   * @param forceLoad boolean
   * @return Promise<any>
   */
  public async loadSingleProjectData(projectId: string, forceLoad: boolean = false): Promise<any> {
    let result;
    const data = await this.buildSyncModel();

    try {
      result = await this.api.post<any>(`/inventory-project/project/synchronize/${projectId}`, { data: data }).toPromise();
    } catch (e) {
      // Ignore error
    }
    if (!result || !result.data) {
      return;
    }

    // Check if project is already available locally
    const existingProject = await Project.query.get({
      id: projectId
    });
    if (!forceLoad && existingProject) {
      return;
    }

    const localProject = await Project.query.get({ id: projectId });
    await this.saveSyncResult(localProject, result, projectId);
  }

  /**
   * Show sync message
   *
   * @param message string
   * @return void
   */
  private showSyncStartToast(message: string): void {
    this.toastService.success('synchronisation.title', message);
  }

  /**
   * Show sync result message
   *
   * @return void
   */
  private showSyncResultToast(): void {
    if (this.state.added === true) {
      this.toastService.success('synchronisation.title', 'synchronisation.data-imported');
    } else {
      this.toastService.success('synchronisation.title', 'synchronisation.data-uptodate');
    }
  }

  /**
   * State of synchronisation as observable
   *
   * @return Observable<any>
   */
  private synchronisationState(): Observable<any> {
    return observableFrom([this.state]);
  }

  /**
   * Lists all tables containing project data
   *
   * @return Promise<any>
   */
  private async getProjectTables(): Promise<any> {
    const tables = [];
    const modules = await this.getSyncTables();

    for (const module of modules) {
      tables.push(module.table);
    }

    return tables;
  }

  /**
   * Build a sync model from de database modules
   *
   * @return Promise<any>
   */
  private async buildSyncModel(): Promise<any> {
    const syncModel = {};

    for (const syncTable of await this.getSyncTables()) {
      if (!syncModel[syncTable.module]) {
        syncModel[syncTable.module] = {};
      }

      syncModel[syncTable.module][syncTable.key] = [];
    }

    return syncModel;
  }

  /**
   * Build the sync json with project data
   *
   * @param project Project
   * @return Promise<any>
   */
  private async getSyncJson(project: Project): Promise<any> {
    const data = await this.buildSyncModel();

    await project.init();

    // Client
    if (project.inventory_client_id && data['inventory-client']) {
      const client = await Client.query.get(project.inventory_client_id);

      if (client && client.id && client.name) {
        data['inventory-client'].client.push(client.getData());
      }
    }

    // Project
    if (data['inventory-project']) {
      data['inventory-project'].project.push(project.getData());
    }

    // Address
    if (data['address']) {
      const addresses = await Address.query
        .where('project_id')
        .equals(project.id)
        .toArray();

      for (const address of addresses) {
        data['address'].address.push(address.getData());
      }
    }

    // Contact
    if (data['contact']) {
      const contacts = await Contact.query
        .where('project_id')
        .equals(project.id)
        .toArray();

      for (const contact of contacts) {
        data['contact'].contact.push(contact.getData());
      }
    }

    // Receipt
    if (data['inventory-receipt']) {
      const receipts = await Receipt.query
        .where('project_id')
        .equals(project.id)
        .toArray();

      for (const receipt of receipts) {
        const receiptData = receipt.getData();

        if (receiptData.inventory_supplier_id) {
          if (data['inventory-supplier']) {
            const Suppliers = await Supplier.query
              .where('id')
              .equals(receiptData.inventory_supplier_id)
              .toArray();

            for (const supplier of Suppliers) {
              data['inventory-supplier'].supplier.push(supplier.getData());
            }
          }
      }
        data['inventory-receipt'].receipt.push(receiptData);
      }
    }

    // Inventory
    if (data['inventory-inventory']) {
      const inventories = await Inventory.query
        .where('project_id')
        .equals(project.id)
        .toArray();

      for (const inventory of inventories) {
        data['inventory-inventory'].inventory.push(inventory.getData());

        await inventory.init();

        const inventoryItems = await InventoryItem.query
          .where('inventory_id')
          .equals(inventory.id)
          .toArray();

        // Inventory items
        for (const inventoryItem of inventoryItems) {
          data['inventory-inventory'].inventory_item.push(inventoryItem.getData());
        }
      }
    }

    if (data['inventory-specialty']) {
      const projectSpecialties = await ProjectSpecialty.query
        .where('project_id')
        .equals(project.id)
        .toArray();

      for (const projectSpecialty of projectSpecialties) {
        data['inventory-specialty'].project_specialty.push(projectSpecialty.getData());
      }
    }

    if (data['inventory-orderpicking']) {
      const OrderPickings = await OrderPicking.query
        .where('project_id')
        .equals(project.id)
        .toArray();

      for (const orderPicking of OrderPickings) {
        await orderPicking.init();

        data['inventory-orderpicking'].orderpicking.push(orderPicking.getData());
      }
    }

    if (data['inventory-shipping-manifest']) {
      const shippingManifests = await ShippingManifest.query
        .where('project_id')
        .equals(project.id)
        .toArray();

      for (const shippingManifest of shippingManifests) {
        await shippingManifest.init();

        data['inventory-shipping-manifest']['shipping-manifest'].push(shippingManifest.getData());
      }
    }

    return data;
  }

  /**
   * Build sync Tables from the active modules stored in de database
   *
   * @return Promise<any>
   */
  private async getSyncTables(): Promise<any> {
    const modules = [];
    const activeModules = await Module.getModules();

    for (const activeModule of activeModules) {
      switch (activeModule.name) {
        case 'inventory-client':
          modules.push({
            module: 'inventory-client',
            key: 'client',
            table: 'clients'
          });

          break;

        case 'inventory-supplier':
          modules.push({
            module: 'inventory-supplier',
            key: 'supplier',
            table: 'suppliers'
          });

          break;
        case 'inventory-orderpicking':
          modules.push({
            module: 'inventory-orderpicking',
            key: 'orderpicking',
            table: 'orderpickings'
          });

          break;
        case 'inventory-project':
          modules.push({
            module: 'inventory-project',
            key: 'project',
            table: 'projects',
            relations: [
              {
                module: 'address',
                key: 'address',
                table: 'addresses',
                attribute: 'addressable_id',
                identifier: 'project_id'
              },
              {
                module: 'contact',
                key: 'contact',
                table: 'contacts',
                attribute: 'contactable_id',
                identifier: 'project_id'
              }
            ]
          });

          break;
        case 'inventory-inventory':
          modules.push({
            module: 'inventory-inventory',
            key: 'inventory',
            table: 'inventories'
          });

          modules.push({
            module: 'inventory-inventory',
            key: 'inventory_item',
            table: 'inventory_items'
          });

          break;
        case 'inventory-receipt':
          modules.push({
            module: 'inventory-receipt',
            key: 'receipt',
            table: 'receipts'
          });

          break;
        case 'inventory-specialty':
          modules.push({
            module: 'inventory-specialty',
            key: 'project_specialty',
            table: 'project_specialties'
          });

          break;

        case 'inventory-shipping-manifest':
          modules.push({
            module: 'inventory-shipping-manifest',
            key: 'shipping-manifest',
            table: 'shippingmanifests'
          });

          break;
      }
    }

    return modules;
  }

  /**
   * Save sync result in local DB
   *
   * @param project Project
   * @param result any
   * @param projectId string
   * @return Promise<any>
   */
  private async saveSyncResult(project: Project, result: any, projectId: string): Promise<any> {
    if (!result || !result.data) {
      return;
    }

    for (const syncTable of await this.getSyncTables()) {
      if (!result.data[syncTable.module] || !result.data[syncTable.module][syncTable.key]) {
        continue;
      }

      const entities = result.data[syncTable.module][syncTable.key];

      await this.storeEntities(entities, syncTable);

      if (syncTable.relations) {
        for (const relation of syncTable.relations) {
          for (const entity of entities) {
            if (!entity['related'][relation.key]) {
              continue;
            }

            const relatedEntity = entity['related'][relation.key];

            await this.storeEntities(relatedEntity, relation);
          }
        }
      }
    }

    // Store a copy of the project tree to track changes
    const updateProject = await Project.query.get({ id: projectId });

    if (updateProject) {
      const copy = await this.getSyncJson(updateProject);
      updateProject._original = JSON.parse(JSON.stringify(copy)); // Clone object

      await this.dataService.createOrUpdate('projects', updateProject);
    }
  }

  /**
   * Store the sync entities
   *
   * @param entities any
   * @param syncTable any
   * @return Promise<any>
   */
  private async storeEntities(entities: any, syncTable: any): Promise<any> {
    // Validate input
    if (entities && entities.deleted) {
      entities = [entities];
    }

    // Get the errors
    let errors = [];
    entities.map(entity => (errors = errors.concat(entity.errors)));

    if (errors.length > 0) {
      // TODO: Do something with the errors
      console.log('Entity sync error...');
    }

    // Get mutated entities
    let mutated = [];
    entities.map(entity => (mutated = mutated.concat(entity.mutated)));

    if (syncTable.attribute) {
      mutated.map(entity => {
        entity[syncTable.identifier] = entity[syncTable.attribute];

        return entity;
      });
    }

    // Save mutated
    await this.store.table(syncTable.table).bulkPut(mutated);

    // Get deleted entities
    let deleted = [];
    entities.map(entity => (deleted = deleted.concat(entity.deleted)));

    // Remove deleted
    await this.store.table(syncTable.table).bulkDelete(deleted);

    // Get "new" entities
    let brandNew = [];
    entities.map(entity => (brandNew = brandNew.concat(entity.new)));

    if (syncTable.attribute) {
      brandNew.map(entity => {
        entity[syncTable.identifier] = entity[syncTable.attribute];

        return entity;
      });
    }

    await this.store.table(syncTable.table).bulkPut(brandNew);
  }

  /**
   * Get the Diff between two entities
   *
   * @param newData any
   * @param oldData any
   * @return any
   */
  private getDiff(newData: any, oldData: any): any {
    let result: any;

    if (Array.isArray(newData)) {
      if (!result) {
        result = [];
      }

      for (const item of newData) {
        if (item === null) {
          continue;
        }

        // Find item with same id in old data and compare
        const oldItem = item && oldData ? oldData.find(dataItem => dataItem.id === item.id) : undefined;
        if (!oldItem) {
          // Mark as new
          item._new = true;
          result.push(item);
        } else {
          // Item exists, add differences only
          if (Array.isArray(item)) {
            const itemDiff = this.getDiff(item, oldItem);

            if (itemDiff) {
              // Always add id field
              itemDiff.id = item[0].id;
              result.push(itemDiff);
            }
          }
        }
      }

      // Check if item is deleted and if the old data contains nested data which could have been updated
      if (oldData) {
        for (const oldDataItem of oldData) {
          if (oldDataItem !== null && typeof oldDataItem === 'object') {
            // Compare child elements as well
            for (const objectKey of Object.keys(oldDataItem)) {
              const newDataItem = newData.find(dataItem => dataItem.id === oldDataItem.id);

              if (Array.isArray(oldDataItem[objectKey])) {
                const itemDiff = this.getDiff(newDataItem ? newDataItem[objectKey] : [], oldDataItem[objectKey]);
                if (itemDiff) {
                  if (!result[objectKey]) {
                    result[objectKey] = itemDiff;
                  } else {
                    result[objectKey] = [...result[objectKey], ...itemDiff];
                  }
                }
              } else if (newDataItem && typeof oldDataItem[objectKey] === 'string') {
                if (oldDataItem[objectKey] !== newDataItem[objectKey]) {
                  result.push(newDataItem);
                }
              }
            }
          }

          if (oldDataItem !== null && newData.filter(dataItem => dataItem.id === oldDataItem.id).length === 0) {
            result.push({ _is_deleted: true, id: oldDataItem.id });
          }
        }
      }

      return Object.keys(result).length === 0 ? undefined : result;
    }

    if (!result) {
      result = {};
    }

    for (const key of Object.keys(newData)) {
      const newEntity = newData[key];
      const oldEntity = oldData[key];

      // Always add if old item not exists
      if (newEntity && !oldEntity) {
        result[key] = newEntity;
        // Mark each array entry as _new if array
        if (Array.isArray(result[key])) {
          for (const item of result[key]) {
            if (typeof item === 'object') {
              // Mark as new
              item._new = true;
            }
          }
        } else if (typeof result[key] === 'object') {
          // Mark as new
          result[key]._new = true;
        }

        continue;
      }

      if (Array.isArray(newEntity)) {
        const itemDiff = this.getDiff(newEntity, oldEntity);

        if (itemDiff) {
          result[key] = itemDiff;
        }

        continue;
      }

      if (newEntity && typeof newEntity === 'object') {
        const itemDiff = this.getDiff(newEntity, oldEntity);

        if (itemDiff) {
          result[key] = itemDiff;
        }

        continue;
      }

      if (newEntity !== oldEntity) {
        result[key] = newEntity;
      }
    }

    if (!result || Object.keys(result).length === 0) {
      return undefined;
    }

    // Add id field if available
    if (newData.id) {
      result.id = newData.id;
    }

    // Remove duplicate project entries
    if (result.project) {
      result.project = result.project.reduce((projects, project) => [
        ...projects.filter((object) => object.id !== project.id), project
      ], []);
    }

    return result;
  }
}
