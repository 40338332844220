import { Component, OnInit, ViewChild } from '@angular/core';
import { ProjectOverviewComponent } from '@features/project/project-overview.component';
import { SettingService } from '@shared/services/setting.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild('projectOverview') projectOverview: ProjectOverviewComponent;

  constructor(private settingService: SettingService) {
  }

  public ngOnInit(): void {
    this.settingService.setGlobalStyles();
  }
}
