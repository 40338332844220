import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { InventoryClientComponent } from '@features/inventory/client/client.component';


@Injectable()
export class ClientGuard implements CanDeactivate<InventoryClientComponent> {
  private allowedRoutes = ['/admin/projects', '/admin/settings'];
  private popup = 'popup';

  canDeactivate(
    component: InventoryClientComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState?: RouterStateSnapshot,
    nextState?: RouterStateSnapshot) {

    if (component.formGroup && !this.allowedRoutes.includes(nextState.url) && !nextState.url.includes(this.popup)) {
      component.markFormsAsDirty();

      if (component.formGroup.invalid) {
        return false;
      }
    }

    return true;
  }
}
