import { DomainModel } from '@domain/domain.model';
import * as uuid from 'uuid/v4';

export class Supplier extends DomainModel {
    // Configuration
    public entity = 'supplier';
    public table = 'suppliers';
    public schema = 'id';
    public sync = true;
    public id: string;

    // Fields
    public name?: string;

    /**
     * Constructor
     *
     * @param attributes
     */
    constructor(attributes) {
        super(attributes);

        if (!attributes.id) {
            this.id = uuid();
        }
    }

    /**
     * Retrieve the supplier
     *
     * @return any
     */
    public getData(): any {
        return {
            id: this.id,
            name: this.name || '',
        };
    }
}
