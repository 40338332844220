import { Component, OnInit, HostListener } from '@angular/core';
import { detectBody } from '../../app.helpers';
import { environment } from '@environments/environment';

declare var jQuery: any;

@Component({
  selector: 'app-basic',
  templateUrl: 'footerNavigationLayout.component.html',
})
export class FooterNavigationLayoutComponent implements OnInit {
  public version: string;
  public environment: any;

  public ngOnInit(): any {
    detectBody();

    this.version = environment.version;
  }

  @HostListener('window:resize') onResize() {
    detectBody();
  }

}
